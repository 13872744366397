import Enumerable from 'linq';
import { elementTypes } from './constants';
import { setProperty } from './property';

export const tableHasValueSet = (tableData) => {

  return tableData
    && tableData.length > 0

    // at least one row as a value that is set. Don't just check for t.value because that would
    // not allow someone to enter 0
    && tableData.filter(t => t.value != null && t.value !== '').length > 0
}

export const getHours = (value) => {
  if (!value) {
    return null
  }
  const chunks = value.split(':')
  if (chunks && chunks.length > 0) {
    const hours = parseInt(chunks[0])
    return hours != null && !isNaN(hours) ? hours : null
  }
  return null
}

export const getMinutes = (value) => {
  if (!value) {
    return null
  }
  const chunks = value.split(':')
  if (chunks && chunks.length === 2) {
    const minutes = parseInt(chunks[1])
    return minutes != null && !isNaN(minutes) ? minutes : null
  }
  return null
}

export const timeHasValueSet = (time) => {
  return getMinutes(time) != null && getHours(time) != null
}

export const getAllCheckboxesOptions = (containers) => {
  var elements = Enumerable.from(containers)
  .selectMany(c => c.rows)
  .selectMany(r => r.columns)
  .selectMany(c => c.elements)
  .toArray();

  let checkboxOptions = [{name: 'None', value: 'none'}]
  const options = elements.filter((element) => {
    return element.type === elementTypes.checkbox
  }).map((element) => {
    return {name: element.property, value: element.id}
  });

  checkboxOptions.push(...options)
  return checkboxOptions;
}

export const onChangeInlineEdit = (e, elementContext, dialogDefinition, clonedData, setClonedData) => {
  const { name, value } = e.target;
  const type = "text"
  const customEventObject = {
    target: {
      name,
      value
    }
  };

  elementContext.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)
  setProperty(clonedData, name, value, type)
  setClonedData((data) => ({
    ...data,
  }));
}

export const calculateRows = (element, data, style, setRows) => {
  if(element) {
    const minRows = data?.rows || 1;
    const maxRows = 60;

    const textareaLineHeight = style.fontSize * 1.4; 
    
    const previousRows = element.rows;
    element.rows = minRows; // reset number of rows in textarea 
    
    const currentRows = ~~(element.scrollHeight / textareaLineHeight);
    
    if (currentRows === previousRows) {
      element.rows = currentRows;
    }
    
    if (currentRows >= maxRows) {
      element.rows = maxRows;
      element.scrollTop = element.scrollHeight;
    }

    setRows(currentRows);
  }
}

/**
 * @param {Event} event
 * @param {} openProperties - elementDesign.onClick 
 */
export const OpenElementProperties = (event, openProperties) => {
  const showPanel = true;
  openProperties(event, showPanel)
}