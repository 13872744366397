import { useState } from 'react';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';
import { defaultBitmap } from '../../data';
import { alignmentOptions } from '../../utils/constants';

export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">

      <Property.Group
        title='Information'>

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Base64Upload
          onChange={handleOnChange}
          label={'Value'}
          name={'value'}
          value={data?.value}
          maxFileSizeInMb={1} // matches backend UpdateDialogDefinitionDesignCommandValidator
          defaultOnDelete={defaultBitmap}
          acceptedExtensions={"image/*"}
        />

      </Property.Group>

      <Property.Group title="Element Style">

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Width'}
          name={'width'}
          value={data.width}
          type={'number'}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Rounded corners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

      </Property.Group>

      <Property.Group
        title='Placement'>

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data.text?.alignment}
          options={alignmentOptions}
        />    

      </Property.Group>
    </div>
  )
}
