import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';

import './styles.scss'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TableInput({ data, id, value, valueChanged, ...props }) {
  const classPrefix = "inputTextLine"

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const elementDesign = useElementDesign(data.id, data);
  const multiStep = useMultiStep(data);

  const onChange = (newValue) => {
    valueChanged(id, newValue);
  }
  
  return (
    <input
      type={"text"}
      id={data.property}
      value={value}
      onChange={onChange}
      //   style={inputStyle}
      className={`element block w-full ${roundedCornerClass} ${classPrefix}`}
      disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
    />
  )
}