import React, { useEffect, useRef, useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import { getDialogObject } from '../../api/dialogObjects';
import _ from "lodash";
import { backgroundSizeOptions } from '../../utils/constants';
import { ContainerContext } from '../../contexts';

export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState(_.cloneDeep(initData));
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const containerContext = React.useContext(ContainerContext)
  const forcePageCheckbox = useRef();
  const avoidPageCheckbox = useRef();

  useEffect(() => setSetData(_.cloneDeep(initData)), [initData])

  const handleOnChange = async (e, type) => {
    const { name, value } = e.target;

    if (name === 'lockToDialogObject') {
      const templateObject = await getDialogObject(initData.dialogObjectId)
      const templateContainer = templateObject.container;
      let cloned = _.cloneDeep(templateContainer);

      const containerIndex = dialogDefinition.containers.findIndex(c => c.id === initData.id)
      const event = {
        target: {
          name: `containers[${containerIndex}]`,
          value: {
            ...cloned,
            id: initData.id,
            lockToDialogObject: true,
            dialogObjectId: templateObject.id
          }
        }
      }
      actions.handleChange(dialogDefinition, event, data.id, null, true)
    }
    else {
      actions.handleChange(dialogDefinition, e, data.id, type)
    }

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  if (data.dialogObjectId && data.readOnly === true) {
    return (
      <Property.Group
        title='Template'
      >
        <div className='px-4 sm:px-6 pt-3 flex items-center justify-between'>
          Template is read-only
        </div>
      </Property.Group>
    )
  }

  return (
    <>
      {
        data.dialogObjectId &&
        <Property.Group
          title='Template'
        >
          <Property.Checkbox
            onChange={handleOnChange}
            label={'Lock to template'}
            name={'lockToDialogObject'}
            value={data.lockToDialogObject}
          />
        </Property.Group>
      }

      <Property.Group
        title='Information'>

        <Property.Text
          onChange={handleOnChange}
          label={'Name'}
          name={'name'}
          value={data?.name}
        />

      </Property.Group>

      {
        containerContext.isTemplate === true &&
        <Property.Group
          title='Template'
        >
          <Property.Checkbox
            onChange={handleOnChange}
            label={'Read-only'}
            name={'readOnly'}
            value={data?.readOnly}
          />
        </Property.Group>
      }

      <Property.Group
        title='Style'>

        <Property.Base64Upload
          onChange={handleOnChange}
          label={'Background Image'}
          name={'backgroundImage'}
          value={data?.backgroundImage}
          acceptedExtensions={"image/*"}
        />

        <div className={`${data?.backgroundImage ? '' : 'hidden'}`}>
          <Property.Select
            onChange={(e) => handleOnChange(e, 'number')}
            label={'Fit image'}
            name={'backgroundSize'}
            value={data?.backgroundSize}
            options={backgroundSizeOptions}
          />

          <Property.Checkbox
            onChange={handleOnChange}
            label={'Repeat image'}
            name={'backgroundRepeat'}
            value={data.backgroundRepeat}
          />
        </div>

        <Property.Color
          onChange={handleOnChange}
          label={'Background color'}
          name={'backgroundColor'}
          initColor={data.backgroundColor}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Rounded corners'}
          name={'roundedCorners'}
          value={data.roundedCorners}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Force page-break on PDF'}
          name={'newPage'}
          value={data?.newPage}
          ref={forcePageCheckbox}
          dependency={avoidPageCheckbox}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Avoid page-break inside on PDF'}
          name={'avoidBreakInside'}
          value={data?.avoidBreakInside}
          ref={avoidPageCheckbox}
          dependency={forcePageCheckbox}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Place section on top for step-by-step'}
          name={'static'}
          value={data?.static}
        />

      </Property.Group>

      <Property.Group
        title='Placement'>

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Margin'}
          name={'margin'}
          value={data.margin}
          type={'margin'}
        />

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Padding'}
          name={'padding'}
          value={data.padding}
          type={'padding'}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Gap'}
          name={'space'} // property name on backend is called "space"
          value={data.space}
          options={[
            { name: "None", value: 0 },
            { name: "Small", value: 2 },
            { name: "Medium", value: 3 },
            { name: "Large", value: 5 },
            { name: "Extra Large", value: 8 },
          ]}
        />

      </Property.Group>

    </>
  )
}
