import { useContext } from 'react'
import { useElementDesign } from "../useElementDesign"
import './styles.scss'
import { DialogModes, FormContext } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle';

export default function HyperLink({ data, ...props }) {
  const classPrefix = "hyperLink"
  const formContext = useContext(FormContext);
  const { isEditMode } = useContext(DialogModes);
  const designElement = useElementDesign(data.id, data);
  const elementStyle = useElementStyle(data);
  
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'white'
  const widthStyle = data?.width ? `${data.width}px` : 'min-content'
  const isButton = data?.isButton;
  const { width, ...borderStyles } = elementStyle.borderAndWidthStyle();
  const textLineStyle = elementStyle.textStyle(data?.text)
  const prefixClass = elementStyle.prefixClassName(classPrefix);

  const buttonStyle = {
    ...textLineStyle,
    ...borderStyles,
    backgroundColor: backgroundColorStyle,
    width: widthStyle,
  }

  const buttonClass = isButton ? `items-center px-6 py-3 border border-transparent font-medium cursor-pointer ${roundedCornerClass}` : ""
  
  return (
    <InlineActionbarElementWrapper openElementProperties designElement={designElement}>
      <a className={`element block ${classPrefix} ${prefixClass} ${buttonClass}`}
          style={isButton ? buttonStyle : textLineStyle}
          href={!isEditMode ? data?.value : false}
          target="_blank"
          rel="noreferrer"
      >
          {designElement.translateTerm(formContext?.inputValues[data.id]?.label || data.label)}
          {designElement.translateHelpText(data)}
      </a>
    </InlineActionbarElementWrapper>
  )
}
