export const Badge = ({
  type = "success", // 'success', 'error', 'warn'
  text,
  className = "",
}) => {

  const colorClasses = () => {
    switch (type) {
      case "success":
        return "bg-green-100 text-green-800";
      case "error":
        return 'bg-red-100 text-red-800';
      case "warn":
        return "bg-yellow-100 text-yellow-800";
      case "info":
        return "bg-blue-100 text-blue-800";
      case "default":  
      default:
        return "bg-gray-100 text-gray-800";
    }
  }


  return (
    <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${colorClasses()} ${className}`}>
      {text}
    </span>
  );
}