import { useState, useContext, useEffect } from 'react';
import { FormContext } from '../../contexts';
import './styles.scss';
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import { DialogModes } from '../../contexts';
import InlineEditing from '../../components/InlineEditing';

export default function Checkbox({ data, ...props }) {
  const classPrefix = "checkbox";
  const multiStep = useMultiStep(data);
  const elementDesign = useElementDesign(data.id, data);
  const { textClassName, textStyle } = useElementStyle(data);
  const { isEditMode } = useContext(DialogModes);
  const formContext = useContext(FormContext);

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'inherit';
  const backgroundColorCheckbox = backgroundColorStyle === "#FFFFFF" || backgroundColorStyle === "#fff" ? '#878686' : backgroundColorStyle;
  const labelClass = textClassName(data?.text);
  const labelStyle = { ...textStyle(data?.text, 'left') };
  const descriptionStyle = { ...textStyle(data?.labelStyle, 'left') };
  const descriptionClass = textClassName(data?.labelStyle);
  
  const [value, setValue] = useState(data.value);

  let currentValueTemp;
  let onChange;
  let inputErrorClasses;
  if (formContext) {
    const formValue = formContext?.inputValues[data.id]?.value
    if (formValue) {
      currentValueTemp = formValue?.toLowerCase() === 'true' ? true : false;
    }
    else {
      currentValueTemp = data.defaultCheckedState ? true : false;
      formContext.updateValue(data.id, currentValueTemp.toString())
    }

    inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, formValue);

    onChange = () => {
      if (currentValueTemp) {
        formContext.updateValue(data.id, 'false')
      }
      else {
        formContext.updateValue(data.id, 'true')
      }
      setValue(!currentValueTemp)
      setCurrentValue(!currentValueTemp)
    }
  }
  else {
    currentValueTemp = value;
    onChange = () => {
      setValue(!value);
      setCurrentValue(!currentValueTemp)
    }
  }

  const [currentValue, setCurrentValue] = useState(currentValueTemp);

  useEffect(() => {
    const formValue = formContext?.inputValues[data.id]?.value
    setCurrentValue((formValue?.toLowerCase() === 'true' ? true : false))
  }, [formContext?.inputValues[data.id]])

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={`flex items-center `}
      >
        <div className={`relative flex items-start w-full ${inputErrorClasses ?? ''}`}>
          <div className="flex items-center h-5">
            <input
              id={`${classPrefix}-${data.id}`}
              aria-describedby={`${classPrefix}-description`}
              name="checkbox"
              type="checkbox"
              style={{ color: backgroundColorCheckbox }}
              className="focus:ring-indigo-500 h-4 w-4 text-red-600 border-gray-300 rounded px-0"
              checked={currentValue}
              defaultChecked={currentValue}
              // value={value}
              onChange={onChange}
              disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
            />
          </div>
          <div className={`${isEditMode ? 'flex flex-col' : '' } ml-3 text-sm w-full`}>
              <InlineEditing
                initData={data}
                style={labelStyle}
                classes={labelClass}
                name='valueLabel'
              >
                <label htmlFor="checkbox" style={labelStyle} className={`break-words ${labelClass}`}>
                    {elementDesign.translateTerm(data.valueLabel)}
                    {elementDesign.translateHelpText(data)}
                    {elementDesign.requiredFieldIndicator(data.requiredField)}
                </label>
              </InlineEditing>
              <InlineEditing
                initData={data}
                style={descriptionStyle}
                classes={descriptionClass}
                name='label'
              >
                <p 
                  id={`${classPrefix}-description`} 
                  style={descriptionStyle} 
                  className={`break-words ${descriptionClass}`}
                >
                  {elementDesign.translateTerm(data.label, 'description')}
                </p>
              </InlineEditing>
          </div>
        </div>
        </div>
    </InlineActionbarElementWrapper>
  )
}