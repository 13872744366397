import { NavLink } from "react-router-dom";
import MenuContextList from "../../../components/MenuContextList/index";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import { editStates } from "../../../utils/constants";
import { isActive } from "../../../utils/dialogDefinitions";
import { Badge } from "../../../components/Badge";
import { DuplicateIcon, CheckIcon, SaveIcon, PencilAltIcon, TrashIcon, BanIcon } from "@heroicons/react/solid";
import * as Constants from "../../../utils/constants";
import { useModalHelper, useToastAction } from "metaforce-core";
import Modal from "../../../components/Modal";
import { FolderIcon } from "@heroicons/react/outline";
import Select from 'react-select';
import { useEffect, useState } from "react";
import { addFolderToDialogDefinition, getDialogDefinitionFolders } from "../../../api/dialogDefinition";

export default function TableBody({data, feature, definitionAction, onClick, selectedFolder, successFolderAction}) {    
    const { duplicate, activate, deactivate } = definitionAction || {};
    const { onDuplicate, onDeactivate, onActivate, onHandleExport, onDelete } = onClick || {};
    const [selectedDialogDefinition, setSelectedDialogDefinition] = useState(null);

    const folderModalHelper = useModalHelper();

    const openMoveToFolder = (dialogDefinitionId) => {
        setSelectedDialogDefinition(dialogDefinitionId);
        folderModalHelper.open();
    }

    return (
        <>
            <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((element, index) => (
                    <tr key={index}>
                        <td className="px-6 py-7 whitespace-nowrap text-sm font-medium text-gray-900 overflow-ellipsis overflow-hidden"
                            style={{ maxWidth: "300px" }}
                        >
                            {
                                <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                        <NavLink to={`/embeddedinternal/${element.id}`}
                                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                            { element?.name }
                                        </NavLink>
                                </span>
                            }
                        </td>
                        <td className="px-6 py-7 whitespace-nowrap text-sm font-medium">
                            <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                { element?.id && !element?.isDeleted && 
                                    <>
                                    { isActive(element) ? (
                                        <span className="text-gray-900">Active</span>
                                    ) : (
                                        <span className="text-gray-400">Inactive</span>
                                    )}
                                    </>
                                }

                                { element?.id && element?.isDeleted && 
                                    <>                                    
                                        <span className="text-gray-400">Deleted</span>
                                    </>
                                }
                            </span>
                        </td>
                        <td className="px-6 py-7 whitespace-nowrap text-sm text-gray-500">
                            <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                { element?.id && !element?.isDeleted && 
                                    <>                                    
                                        { element.editState === editStates.draft ? (
                                            <Badge type="default" text="Draft" />
                                        ) : (
                                            <Badge type="success" text="Published" />
                                        )}
                                    </>
                                }

                                { element?.id && element?.isDeleted && 
                                    <>                                    
                                        <Badge type="error" text="Deleted" />
                                    </>
                                }
                        
                            </span>
                        </td>
                        <td className="px-6 py-7 whitespace-nowrap text-sm text-gray-500">
                            <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                {stringToLocaleDateTimeString(element?.createdDate)}
                            </span>
                        </td>
                        <td className="px-6 py-7 whitespace-nowrap text-sm text-gray-500">
                            <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                                {stringToLocaleDateTimeString(element?.updatedDate)}
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>

            <MoveToFolderModal successFolderAction={successFolderAction} modalHelper={folderModalHelper} selectedDialogDefinition={selectedDialogDefinition} />
        </>
    );
}

const MoveToFolderModal = ({ modalHelper, selectedDialogDefinition, successFolderAction }) => {
    const loadAction = useToastAction();
    const moveAction = useToastAction();
    const [folderOptions, setFolderOptions] = useState([]);
    const [selectedFolderOption, setSelectedFolderOption] = useState(null);

    useEffect(() => {
        load();
    } , [modalHelper.isOpen]);

    async function load() {
        loadAction.execute(async () => {
            const options = []

            const result = await getDialogDefinitionFolders();
            options.push({
                value: "-1",
                label: "Home"
            })

            result.forEach(folder => {
                options.push({
                    value: folder.id,
                    label: folder.name
                })
            });

            setFolderOptions(options);
        }, "Failed to load folders")
    }

    const onCancel = () => {
      modalHelper.close();
    }

    const onMove = () => {
        moveAction.execute(async () => {
            const folderToDialogDefinition = {
                dialogDefinitionId: selectedDialogDefinition,
                folderId: selectedFolderOption
            }

            await addFolderToDialogDefinition(folderToDialogDefinition);
            successFolderAction();
            modalHelper.close();
        }, "Failed to move dialog")
    } 
  
    return (
      <Modal
        isOpen={modalHelper.isOpen}
        onClose={modalHelper.close}
        size="medium"
        title="Move to Folder"
      >
        <Select
            className="relative"
            name="language"
            options={folderOptions}
            onChange={(event) => {
                setSelectedFolderOption(event.value);
            }}
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
        <div className="mt-10 flex gap-2 justify-end">

            <button onClick={onMove} type="button" className="mt-3 ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm">
                Move form
            </button>
            <button onClick={onCancel} type="button" className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm">
                Cancel
            </button>

        </div>
        {/* <Formik
          initialValues={{
            name: record?.name || '',
            emailOnCompletion: record?.emailOnCompletion || false,
            digitalSigning: record?.digitalSigning || false,
          }}
          validationSchema={() => {
            return yup.object({
              name: yup.string().required('Required'),
            })
          }}
          onSubmit={async (values, actions) => {
            await onSave(values)
            actions.setSubmitting(false)
            modalHelper.close();
          }}
        >
          {
            props => (
              <Form>
                <FormikTextInput
                  label="Name"
                  name="name"
                  required={true}
                  formikProps={props}
                />
                <div className="mt-4">
                  <FormikCheckbox
                    label={"Email on completion"}
                    name={'emailOnCompletion'}
                    secondaryLabel="Send Smarform to party when complete (includes PDF if generated)."
                    formikProps={props}
                  />
                </div>
                <div className="mt-4">
                  <FormikCheckbox
                    label={"Digital Signing"}
                    name={'digitalSigning'}
                    secondaryLabel="Party will need to digitally sign forms."
                    formikProps={props}
                  />
                </div>
                <div className="mt-10 flex justify-between">
                  <FormikSubmitButton
                    formikProps={props}
                    text="Save"
                  />
                  <Button
                    onClick={cancel}
                    text="Close"
                    theme="white"
                  />
                </div>
              </Form>
            )
          }
        </Formik> */}

      </Modal>
    )
  }
