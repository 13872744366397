import { useContext, useState } from 'react';
import ColorPicker from '../../components/ColorPicker';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState(initData)
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  return (
    <>
      <Property.Group
        title='Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Background color'}
          name={'backgroundColor'}
          initColor={data.backgroundColor}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Rounded corners'}
          name={'roundedCorners'}
          value={data.roundedCorners}
        />

      </Property.Group>

      <Property.Group
        title='Placement'>

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Margin'}
          name={'margin'}
          value={data.margin}
          type={'margin'}
        />

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Padding'}
          name={'padding'}
          value={data.padding}
          type={'padding'}
        />

       <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Gap'}
          name={'gap'}
          value={data.gap}
          options={[
            { name: "None", value: 0 },
            { name: "Small", value: 2 },
            { name: "Medium", value: 3 },
            { name: "Large", value: 5 },
            { name: "Extra Large", value: 8 },
          ]}
        />

      </Property.Group>

    </>
  )
}
