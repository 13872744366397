import React, { useContext, useEffect, useRef, useState } from 'react'
import SignaturePad from "signature_pad"
import { DialogModes, ElementContext } from '../../contexts';
import { Button } from "../Form/Button"
import _ from "lodash";
import { useSelector } from 'react-redux';
import { onChangeInlineEdit } from '../../utils/elements';
import { useAutoSize } from '../../hooks/useAutoSize';

// TODO use this component from Elements/Signature
export const Signature = ({
  id,
  label = null,
  helpTooltip = null,
  value = null,
  height = null,
  readOnly,
  onUpdate,
  required,
  containerClassName,
  canvasClassName,
  onBlur,
  onClick,
  borderWidthStyle,
  borderColorStyle,
  onSubmit = null,
  name = '',
  heightStyle,
  data,
  labelStyle
}) => {
  const dialogModesContext = useContext(DialogModes);
  const isEditMode = dialogModesContext?.isEditMode || false;
  const wrapper = useRef()
  const signaturePad = useRef();
  const canvas = useRef();
  const labelRef = useRef();
  const textRef = useRef();
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const elementContext = useContext(ElementContext);

  const [clonedData, setClonedData] = useState(_.cloneDeep(data));

  useAutoSize(labelRef.current, clonedData?.value, true)
  useAutoSize(textRef.current, clonedData?.placeholder, true)

  const setDevicePixelRatio = () => {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);

    canvas.current.width = canvas.current.offsetWidth * ratio;
    canvas.current.height = canvas.current.offsetHeight * ratio;
    canvas.current.getContext("2d").scale(ratio, ratio);

    signaturePad.current.clear();
  }

/*   const onWindowResize = () => {
    setDevicePixelRatio();
    onChange();
  } */

  useEffect(() => {
    wrapper.current = document.getElementById(`signature-pad-${id}`);
    canvas.current = wrapper.current.querySelector("canvas");
    signaturePad.current = new SignaturePad(canvas.current, {
      backgroundColor: 'rgb(255, 255, 255)',
      onEnd: onChange
    });

    /* 
      Docs say to call onresize event whenever screen resizes, but that clears out the signature value.
      Because our forms seem to mostly be fixed width (until screen gets too narrow), a screen resize will rarely
      affect the signature size. Therefore we can almost always be safe to just leave it alone. If the screen size
      does change too much, the pixel ration will be off when trying to redraw the signature as the ratio will be set
      from the initial load.
    */
    //window.onresize = onWindowResize;
    setDevicePixelRatio();

    if (value) {
      signaturePad.current.fromDataURL(value)
    }

    if (readOnly) {
      signaturePad.current.off();
    }

    return () => {
      signaturePad.current.clear();
      signaturePad.current.off();
    }
  }, [])

  const onChange = () => {
    const newValue = signaturePad.current.isEmpty() ? null : signaturePad.current.toDataURL();
    if (onUpdate) {
      onUpdate(newValue)
    }
  }

  const onChangeInline = (e) => {
    onChangeInlineEdit(e, elementContext, dialogDefinition, clonedData, setClonedData)
  }

  const onClear = (evt) => {
    evt.preventDefault();
    signaturePad.current.clear();
    onChange();
  }

  let canvasStyles = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    height: heightStyle
  }

  const localLabelStyle = { 
    lineHeight : 1.4,
    ...labelStyle
  }

  if (height) {
    canvasStyles.height = height
  }

  useEffect(() => {
    setClonedData(_.cloneDeep(data));
  }, [data]);

  return (
    <div
      id={`signature-pad-${id}`}
      className={`w-full ${containerClassName} bg-transparent`}
      onClick={onClick ? onClick : null}
      onBlur={onBlur ? onBlur : null}
    >
      {
        isEditMode ? 
        <textarea
            id={`label-${id}`}
            ref={labelRef}
            style={localLabelStyle} 
            className={`block text-sm font-medium text-gray-700 w-full px-0 border-0 resize-none`}
            name="value"
            value={clonedData?.value || ""}
            onChange={onChangeInline}
          />
        : 
        <label className="block text-sm font-medium text-gray-700">
          {label}
          {required === true && <span className="text-red-400"> *</span>}
          {helpTooltip && helpTooltip}
        </label>
      }
      {
        !label &&
        <label className="block text-sm font-medium text-gray-700">
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      }
      {
        required && <span className="text-red-500"> *</span>
      }
      <canvas
        className={`w-full border  ${readOnly ? 'cursor-not-allowed' : ''} ${canvasClassName ? canvasClassName : ''}`}
        onClick={onBlur ? onBlur : null}
        style={{ ...canvasStyles }}
      />
      {
        isEditMode ? 
        <textarea
            id={`text-${id}`}
            ref={textRef}
            style={localLabelStyle} 
            className={`pt-2 text-sm w-full px-0 border-0 resize-none`}
            name="placeholder"
            value={clonedData?.placeholder || ""}
            onChange={onChangeInline}
          />
        : 
        <div className='pt-2 text-sm'>
          {name}
        </div>
      }
      {
        !readOnly &&
        <div className="mt-2 mb-2">
          <Button
            className="print:hidden"
            text="Clear signature"
            theme="text"
            onClick={onClear}
          />
          {
            onSubmit &&
            <Button
              className="print:hidden ml-4"
              text="Complete signature"
              onClick={onSubmit}
            />
          }
        </div>
      }
    </div>
  );
}