import { useContext } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { GlobalDndContext } from '../../contexts';

import { DragHandle } from './dragHandle';

/**
 * @typedef {Object} Props
 * @prop {string} className
 * @prop {string} id
 * @prop {('container'|'row'|'column'|'element')} type
 * @prop {boolean} dragHandle
 * @prop {string} dragHandleTooltip
 */

/**
 * Sortable component for dnd-kit 
 * @param {Props} param0 
 * @returns 
 */
export const DndSortable = ({
  className,
  children,
  id,
  type,
  dragHandle,
  dragHandleTooltip,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id, data: {
      type
    }
  });
  const globalDndContext = useContext(GlobalDndContext)

  const isDragTypeEnabled = globalDndContext.isTypeEnabled(type)

  let dndProps = isDragTypeEnabled
    ? { ...attributes, ref: setNodeRef }
    : {}
  dndProps = isDragTypeEnabled && !dragHandle
    ? { ...dndProps, ...listeners }
    : dndProps

  transform?.scaleY && (transform.scaleY = 1)
  transform?.scaleX && (transform.scaleX = 1)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (dragHandle) {
    return (
      <div
        className={`${className} ${attributes['aria-pressed'] ? 'border-2 border-red-600' : ''}`}
        style={style}
        {...dndProps}
      >
        <DragHandle
          isHandleVisible={isDragTypeEnabled}
          dragHandleProps={listeners}
          forSortableType={type}
          tooltip={dragHandleTooltip}
        >
          {children}
        </DragHandle>
      </div>
    )
  }

  return (
    <div
      style={style}
      {...dndProps}
      className={`${attributes['aria-pressed'] ? 'border-2 border-red-600' : ''}`}
    >
      {children}
    </div>
  );
}