import React, { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { useQuill } from 'react-quilljs';
import Quill from "quill";
import MagicUrl from "quill-magic-url";

const defaultRichTextData = {
  type: 22,
  property: 'Logo',
  value: 'no value',
  placeholder: 'Metaforce logo',
  layout: {
    order: 5,
  },
  styles: {
    borderWidth: 0
  }
}

Quill.register('modules/magicUrl', MagicUrl);

export default function RichTextWrapper({ data, handleOnChangeContent, elementsClasses, showHeaderAlways, onInputClicked, ...props }) {
  data = data || defaultRichTextData
  const modules = {
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
    toolbar: [
      [{ 'font': ['sans-serif', 'serif', 'monospace'] }],
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ color: [] }, { background: [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],

      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ],
  };
  const formats = ["align", "list", "bullet", "link", "image", "background", "color", "size", "font", "underline", "strike", "italic", "bold"]

  const { quill, quillRef } = useQuill({ modules, formats });
  const componentRef = useRef()
  const [firstRender, setFirstRender] = useState(true);
  const [clonedData, setSetData] = useState(_.cloneDeep(data));

  const style = {
    width: '100%',
    height: 'max-content',
    maxHeight: "50rem",
    backgroundColor: 'white',
    border: 'none',
    wordBreak: "break-all"
  }

  const showToolbar = (show) => {
    if (!showHeaderAlways && componentRef.current) {
      const qlSnowSelectElement = componentRef.current.getElementsByClassName("ql-toolbar")
      qlSnowSelectElement && (qlSnowSelectElement[0].hidden = !show)
      quillRef.current.style.border = !show ? "none" : ""
      componentRef.current.style.padding = !show ? "0px" : "1px"

      let editor = quillRef.current.getElementsByClassName('ql-editor')[0]

      if (editor) {
        editor.style.padding = !show ? "0" : "12px 15px"
      }
    }
  }



  useEffect(() => {
    if (quill) {
      const qlSnowSelectElement = componentRef.current && componentRef.current.getElementsByClassName("ql-snow")
      window.addEventListener('click', function (e) {
        const getSelection = window.getSelection()
        const selectionClassName = getSelection?.anchorNode?.ownerDocument?.activeElement?.className || ""
        showToolbar(qlSnowSelectElement[0]?.contains(e?.target) || qlSnowSelectElement[1]?.contains(e?.target) || selectionClassName === `ql-editor-${data?.id}`)
      });

      clonedData?.richTextValues?.contentInHtml && quill.clipboard.dangerouslyPasteHTML(clonedData?.richTextValues?.contentInHtml);
      firstRender && showToolbar()
      quill.on('text-change', (delta, oldDelta, source) => {
        const valueInHtml = quillRef?.current
        handleOnChangeContent(valueInHtml?.firstChild?.innerHTML);
      });
      setFirstRender(false)
    }
  }, [quill]);

  return (
    <div
      ref={componentRef}
      className={`mb-1 ${elementsClasses}`}
      style={style}
      onClick={onInputClicked}
    >
      <div ref={quillRef} />
    </div>
  )
}