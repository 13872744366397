import { useEffect, useState } from 'react';
import { setProperty } from '../../utils/property';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { validateDataTypeOptions, validateDataTypes, countriesOptions, dependenciesOptions } from "../../utils/constants";
import { getAllCheckboxesOptions } from "../../utils/elements";
import { getAllApiDefinitions } from '../../api/apiDefinition'
import { getWorkflowResources } from '../../api/workflowDefinition';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const [apiDefinitionOptions, setApiDefinitionOptions] = useState([]);
  const [workflowResourceOptions, setWorkflowResourceOptions] = useState([]);

  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)
  // const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  useEffect(() => {
    loadApiDefinitionOptions();
    loadWorkflowResources();
  }, [])

  const loadApiDefinitionOptions = async () => {
    // TODO - instead of loading this for every single Dropdown (and any other component it's loaded in), load it
    // further up the component tree once, set it in either a context or a reducer, and reference it
    var apiDefinitions = await getAllApiDefinitions()
    var options = [{ value: "", name: "None" }]

    apiDefinitions.forEach(apiDefinition => {
      options.push({
        value: apiDefinition.id,
        name: apiDefinition.name
      })
    })

    setApiDefinitionOptions(options)
  }

  const loadWorkflowResources = async () => {

    const workflowResources = await getWorkflowResources();
    console.log(workflowResources);

    setWorkflowResourceOptions([{ value: "", name: "None" }, ...workflowResources.map(({ id, name }) => ({ value: id, name }))])

  }

  let checkboxOptions = getAllCheckboxesOptions(dialogDefinition.containers);

  return (
    <div className="absolute inset-0">
      <Property.Group
        title='Information'>

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'label'}
          value={data.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Placeholder'}
          name={'placeholder'}
          value={data?.placeholder}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Initial value"}
          name={"initialValue"}
          value={data.initialValue}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

      </Property.Group>

      <Property.Group title="Formatting">

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={"Validate data"}
          name={"validateData"}
          value={data?.validateData}
          options={validateDataTypeOptions}
        />

        {data?.validateData === validateDataTypes.text &&
          <>
            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Max number of characters'}
              name={'maxLength'}
              value={data?.maxLength}
            />

            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Min number of characters'}
              name={'minLength'}
              value={data?.minLength}
            />
          </>
        }

        {data?.validateData === validateDataTypes.number &&
          <>
            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Max value'}
              name={'maxValue'}
              value={data?.maxValue}
            />

            <Property.Text
              type={"number"}
              onChange={handleOnChange}
              label={'Min value'}
              name={'minValue'}
              value={data?.minValue}
            />
          </>
        }

        {data?.validateData === validateDataTypes.SSN &&
          <>
            <Property.Select
              onChange={(e) => handleOnChange(e, 'number')}
              label={"Country"}
              name={"country"}
              value={data?.country}
              options={countriesOptions}
            />
          </>
        }

        {data?.validateData === validateDataTypes.Api &&
          <>
            <Property.Select
              onChange={handleOnChange}
              label={"Api Validation"}
              name={"apiValidationId"}
              value={data?.apiValidationId}
              options={apiDefinitionOptions}
            />
          </>
        }

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Required field"}
          name={"requiredField"}
          value={data?.requiredField}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />
      </Property.Group>


      <Property.Group title="Element Style">
        <Property.Color
          onChange={handleOnChange}
          label={'Background'}
          name={'backgroundColor'}
          initColor={data.backgroundColor}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Label top"}
          name={"labelTop"}
          value={data?.labelTop}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Hide label"}
          name={"hideLabel"}
          value={data?.hideLabel}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Rounded Corners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

      </Property.Group>

      <Property.Group title='Dependencies'>

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Type'}
          name={'dependencies.type'}
          value={data?.dependencies?.type}
          options={dependenciesOptions}
        />

        {data?.dependencies?.type === 1 &&
          <Property.Select
            onChange={handleOnChange}
            label={'Checkbox'}
            name={'dependencies.value'}
            value={data?.dependencies?.value}
            options={checkboxOptions}
          />
        }

      </Property.Group>

      <Property.Group title='Api Retrieve'>

        <Property.Select
          onChange={handleOnChange}
          label={"Api Retrieve"}
          name={"apiRetrieveId"}
          value={data?.apiRetrieveId}
          options={apiDefinitionOptions}
        />

        <Property.Select
          onChange={handleOnChange}
          label={"Workflow Retrieve"}
          name={"workflowRetrieveId"}
          value={data?.workflowRetrieveId}
          options={workflowResourceOptions}
        />

      </Property.Group>

      <Property.Group
        title='Text Style'>

        <Property.Text
          onChange={handleOnChange}
          label={'Label Width'}
          name={'labelStyle.width'}
          type={'number'}
          value={data?.labelStyle?.width}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Element Width'}
          name={'width'}
          type={'number'}
          value={data?.width}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Text Color'}
          name={'text.color'}
          initColor={data.text.color}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Label Color'}
          name={'labelStyle.color'}
          initColor={data.labelStyle?.color || '#000'}
        />


        <Property.Text
          onChange={handleOnChange}
          label={'Suffix'}
          name={'trailingText'}
          value={data?.trailingText}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Prefix'}
          name={'leadingText'}
          value={data?.leadingText}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Text Size'}
          name={'text.size'}
          type={'number'}
          value={data?.text?.size}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Label Size'}
          name={'labelStyle.size'}
          type={'number'}
          value={data?.labelStyle?.size}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />

      </Property.Group>


      <Property.Group
        title='Placement'>

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Label alignment"}
          name={"labelStyle.alignment"}
          value={data?.labelStyle?.alignment}
          options={[
            { name: "Left", value: 'left' },
            { name: "Center", value: 'center' },
            { name: "Right", value: 'right' }
          ]}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data.text?.alignment}
          options={[
            { name: "Left", value: 'left' },
            { name: "Center", value: 'center' },
            { name: "Right", value: 'right' }
          ]}
        />

      </Property.Group>

    </div>
  )
}
