import React, { useState, useContext, useEffect } from 'react'
import * as _ from 'lodash'
import { useElementDesign } from '../useElementDesign'
import { MinusIcon, TrashIcon } from "@heroicons/react/outline"
import "./styles.scss";
import { PlusButton } from '../../components/PlusButton';
import { useSelector } from 'react-redux';
import { DialogModes, ElementContext } from '../../contexts';
import { setProperty } from '../../utils/property';
import { v4 as uuidv4 } from 'uuid';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';

const defaultDynamicTableData = {
    type: 22,
    property: 'Dynamic Table',
    layout: {
        order: 5,
    },
    styles: {
        borderWidth: 0
    }
}

export default function DynamicTable({ data, ...props }) {
    data = data || defaultDynamicTableData
    const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
    const elementDesign = useElementDesign(data.id, data)
    const [clonedData, setSetData] = useState(_.cloneDeep(data));
    const elementContext = useContext(ElementContext);
    const { isPreviewMode } = useContext(DialogModes)
    const distinctPositions = [...new Set(clonedData.dynamicTableValues.rows?.map(row => row?.position))]
    const iconsClassNames = `h-5 w-5 mx-1 cursor-pointer`

    const handleChanges = async (routeChanges, value) => {
        const name = routeChanges
        //  onAdd ? `dynamicTableValues.${onAdd}` : "dynamicTableValues"
        const type = "text"
        const customEventObject = {
            target: {
                name: name,
                value
            }
        };

        await elementContext.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)

        setProperty(clonedData, name, value, type)
        setSetData(clonedData => ({
            ...clonedData
        }));
    };

    const onAddRow = () => {
        const columnsId = clonedData.dynamicTableValues.columns.map((column) => column.id);
        const position = distinctPositions.length + 1;
        const buildAddRowObject = Object.assign([], clonedData.dynamicTableValues.rows);

        columnsId.map(columnId => (buildAddRowObject.push({
            columnId,
            position,
            value: ""
        })
        ))
        handleChanges("dynamicTableValues.rows", buildAddRowObject)
    }

    const onDeleteRow = async () => {
        const rowsToDelete = clonedData.dynamicTableValues.rows.filter(row => row.position === distinctPositions[distinctPositions.length - 1])
        const buildAddRowObject = Object.assign([], clonedData.dynamicTableValues.rows);

        rowsToDelete.map(rowToDelete => {
            const rowFindIndex = buildAddRowObject.findIndex(row => row.columnId === rowToDelete.columnId && row.position === rowToDelete.position);

            return rowFindIndex !== -1 && buildAddRowObject.splice(rowFindIndex, 1)
        }
        )

        await handleChanges("dynamicTableValues.rows", buildAddRowObject)
    }

    const onAddColumn = async () => {
        const buildAddColumnObject = Object.assign([], clonedData.dynamicTableValues.columns);
        const buildTitle = `Title${buildAddColumnObject.length + 1}`
        const columnId = uuidv4();

        const buildNewRowsOfColumn = Object.assign([], clonedData.dynamicTableValues.rows)

        buildAddColumnObject.push({
            title: buildTitle,
            width: 50,
            id: columnId
        })

        distinctPositions.map(position => {
            return buildNewRowsOfColumn.push({
                position,
                value: "",
                columnId: columnId
            })
        })


        await handleChanges("dynamicTableValues.rows", buildNewRowsOfColumn)
        await handleChanges("dynamicTableValues.columns", buildAddColumnObject)

        // buildResizableColumns();

    }

    const onDeleteColumn = async (id) => {
        const columnById = clonedData.dynamicTableValues.columns.find(col => col.id === id)
        const columnIndex = clonedData.dynamicTableValues.columns.findIndex(col => col === columnById)
        const columnsArray = Object.assign([], clonedData.dynamicTableValues.columns);
        const rowsArray = Object.assign([], clonedData.dynamicTableValues.rows);

        const rowsByColumnId = Object.assign([], clonedData.dynamicTableValues.rows.filter(row => row.columnId === columnById.id));

        rowsByColumnId.map(rowToDelete => {
            const rowFindIndex = rowsArray.findIndex(row => row === rowToDelete);

            return rowFindIndex !== -1 && rowsArray.splice(rowFindIndex, 1)
        }
        )

        columnsArray.splice(columnIndex, 1)

        await handleChanges("dynamicTableValues.rows", rowsArray)
        await handleChanges("dynamicTableValues.columns", columnsArray)

        // buildResizableColumns();
    }

    const handleCellChange = async ({ columnId, index, value }) => {
        const whatIsEdited = `${columnId ? "rows" : "columns"}`
        const clonedTableValues = clonedData.dynamicTableValues;
        let arrayOfEdit = Object.assign([], clonedData.dynamicTableValues[whatIsEdited])
        arrayOfEdit[index] = { ...arrayOfEdit[index], [columnId ? "value" : "title"]: value }; 

        clonedTableValues[whatIsEdited] = Object.assign([], arrayOfEdit)

        await handleChanges(`dynamicTableValues`, clonedTableValues)
    }
    
    //#region Resize columns
    const onResizeColumn = async (e, index, width) => {
        const columnsArray = Object.assign([], clonedData.dynamicTableValues.columns);

        columnsArray[index] = { ...columnsArray[index], width: width };
        await handleChanges(`dynamicTableValues.columns`, columnsArray)
    }

    const createResizableColumn = function (col, resizer) {
        // Track the current position of mouse
        let x = 0;
        let w = 0;

        const mouseDownHandler = function (e) {
            // Get the current mouse position
            x = e.clientX;

            // Calculate the current width of column
            const styles = window.getComputedStyle(col);
            w = parseInt(styles.width, 10);

            // Attach listeners for document's events
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            // Determine how far the mouse has been moved
            const dx = e.clientX - x;

            // Update the width of column
            col.style.width = `${w + dx}px`;
            onResizeColumn(e, col.cellIndex, w + dx)
        };

        // When user releases the mouse, remove the existing event listeners
        const mouseUpHandler = function () {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        resizer.addEventListener('mousedown', mouseDownHandler);
    };

    const buildResizableColumns = () => {
        // Query the table
        const table = document.getElementById('resizeMe');

        // Query all headers
        const cols = table.querySelectorAll('th');

        // Loop over them
        [].forEach.call(cols, function (col, index) {
                const colWidth = clonedData.dynamicTableValues.columns[col.cellIndex]

                // Create a resizer element
                const resizer = document.createElement('div');

                resizer.classList.add('resizer');

                // Set the height
                resizer.style.height = `${table.offsetHeight}px`;
                col.style.width = `${colWidth?.width}px`
                col.style.minWidth = `${0}px`
                // Add a resizer element to the column
                col.appendChild(resizer);

                // Will be implemented in the next section
                createResizableColumn(col, resizer);
        });
    }

    useEffect(() => {
        !isPreviewMode && buildResizableColumns();
    }, [clonedData])
    //#endregion
    
    const elementStyle = useElementStyle(data);
    const { width, ...borderStyle} = elementStyle.borderAndWidthStyle();
    const roundedCornerClass = data?.roundedCorners ? 'sm:rounded-lg' : '';
    const tableStyle = { ...borderStyle }
    
    const labelStyle = elementStyle.textStyle(data?.labelStyle);
    const labelClassName = elementStyle.textClassName(data?.labelStyle)
    
    const textStyle = elementStyle.textStyle(data?.text);
    const textClassName = elementStyle.textClassName(data?.text)

    const valueWithTranslation = (type, index, field) => {
        const translatedData = elementDesign.translateTerm(clonedData.dynamicTableValues, "dynamicTableValues")

        return translatedData[type][index][field];
    }

    return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
        <div className={`${elementDesign.elementsClasses()} flex flex-col`}>
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className='flex items-center'>
                        <div 
                            style={tableStyle}
                            className={`flex overflow-hidden border-b border-gray-200 ${roundedCornerClass}`}
                        >
                            <table id="resizeMe" className="table-fixed min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        {
                                            clonedData.dynamicTableValues.columns.map((column, index) => {
                                                return (<th
                                                    key={index}
                                                    scope="col"
                                                    className="relative px-6 py-3 uppercase tracking-wider"
                                                    style={isPreviewMode ? { ...labelStyle, width: column.width } : {}}
                                                >
                                                    <div className={`flex justify-between items-center ${isPreviewMode ? labelClassName : ''}`}>
                                                    {isPreviewMode ?
                                                        valueWithTranslation("columns", index, "title")
                                                    : <input
                                                        className={`w-full overflow-ellipsis uppercase tracking-wider ${labelClassName}`}
                                                        name="title"
                                                        value={column.title}
                                                        initialwidth={column.width}
                                                        style={labelStyle}
                                                        onChange={(e) => handleCellChange({ index, value: e.target.value })}
                                                       />
                                                    }
                                                        {!isPreviewMode &&
                                                            <TrashIcon
                                                                className={`${iconsClassNames}`}
                                                                onClick={() => onDeleteColumn(column?.id)}
                                                            />
                                                        }
                                                    </div>
                                                </th>
                                                )
                                            })
                                        }

                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        distinctPositions.map(position => {
                                            return (
                                                <tr key={position}>
                                                    {
                                                        clonedData.dynamicTableValues.columns.map((column, columnIndex) => {
                                                            const getCellValue = clonedData.dynamicTableValues.rows.find(row => row?.columnId === column?.id && row.position === position)
                                                            const cellIndex = clonedData.dynamicTableValues.rows.findIndex(row => row === getCellValue)

                                                            return (
                                                                <td key={`${columnIndex}`}
                                                                    className={`px-6 py-4 whitespace-nowrap`}
                                                                    style={isPreviewMode ? { width: column.width } : {}}
                                                                    initialwidth={column.width}
                                                                >
                                                                    <div className='flex justify-between items-center'>
                                                                        {isPreviewMode ?
                                                                            <div className={textClassName}
                                                                                style={textStyle}
                                                                            >
                                                                                {valueWithTranslation("rows", cellIndex, "value")}
                                                                            </div>
                                                                            : <input
                                                                                className={`w-full overflow-ellipsis ${textClassName}`}
                                                                                style={textStyle}
                                                                                name="value"
                                                                                value={getCellValue?.value || ""}
                                                                                onChange={(e) => handleCellChange({ index: cellIndex, value: e.target.value, columnId: getCellValue.columnId })}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {!isPreviewMode &&
                            <div
                                className="px-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <PlusButton
                                    onClick={() => onAddColumn()}
                                />
                            </div>
                        }
                    </div>
                    {!isPreviewMode &&
                        <div className='flex items-center'>
                            <div className='flex-grow bg-blue-500 h-1' />
                            <div className='flex-grow-0 text-center'>
                                <PlusButton
                                    onClick={() => onAddRow()}
                                />
                                <button
                                    type="button"
                                    onClick={() => onDeleteRow()}
                                    className="inline-flex items-center border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-blue-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-300 px-1"
                                >
                                    <MinusIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className='flex-grow bg-blue-500 h-1' />
                        </div>
                    }
                </div>
            </div>
        </div>
    </InlineActionbarElementWrapper>
    )
}
