import { DialogModes, ElementContext } from "../../contexts";
import { setProperty } from "../../utils/property";
import { useElementDesign } from "../../elements/useElementDesign";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { OpenElementProperties } from "../../utils/elements";
import { useAutoSize } from "../../hooks/useAutoSize";

/**
 * Returns a textarea for InlineEditing or his own children
 * @param {Object} initData 
 * @param {Object} style  - Inline styles for textarea
 * @param {String} classes - className for textarea
 * @param {String} name - name for textarea
 * @param {String} id - id for textarea - optional
 * @param {String} value - value for textarea
 * @param {Boolean} autoSize - enable auto size for textarea
 */
export default function InlineEditing({
    initData,
    style,
    classes = "",
    name,
    children,
    id = null,
    value = null,
    autoSize = false
}) {
    const elementContext = useContext(ElementContext) || {};
    const { isEditMode } = useContext(DialogModes);
    const [clonedData, setClonedData] = useState(_.cloneDeep(initData));
    const [inputValue, setInputValue] = useState(value ?? initData[name] ?? '');
    const inputRef = useRef();

    const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
    const elementDesign = useElementDesign(initData.id, initData);
    const isLocked =  elementDesign.isLocked();
    useAutoSize(inputRef.current, inputValue, autoSize, false, style)

    const onChange =  useCallback((e) => {
        const { name, value } = e.target;
        const type = "text"
        const customEventObject = {
            target: {
                name,
                value
            }
        };

        elementContext?.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)
        setProperty(clonedData, name, value, type);
        setClonedData((data) => ({
            ...data,
        }));
        setInputValue(value)
    }, [clonedData, dialogDefinition, elementContext?.actions]);

    useEffect(() => {
        setClonedData(_.cloneDeep(initData));
        setInputValue(initData[name])
    }, [initData, name]);


    return (
        isEditMode && !isLocked ?
            <textarea
                id={id ?? `inline-editing-${clonedData.id}`}
                ref={inputRef}
                rows={1}
                style={style}
                className={`${classes} bg-transparent resize-none border-0 px-0 py-0`}
                name={name}
                value={inputValue}
                onChange={onChange}
                onClick={(e) => OpenElementProperties(e, elementDesign.onClick)}
            />
        : 
            children
    );
}