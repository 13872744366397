import React, { useState, useContext } from 'react'
import * as _ from 'lodash'
import { useElementDesign } from '../useElementDesign'
import { TrashIcon } from "@heroicons/react/outline"
import "./styles.scss";
import { PlusButton } from '../../components/PlusButton';
import { useSelector } from 'react-redux';
import { ElementContext } from '../../contexts';
import { setProperty } from '../../utils/property';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import { DialogModes } from '../../contexts';

const defaultDynamicTableData = {
    type: 22,
    property: 'Dynamic Table',
    layout: {
        order: 5,
    },
    labelStyle: {
        size: 12
    },
    styles: {
        borderWidth: 0
    }
}

export default function DynamicTable({ data, ...props }) {
    data = data || defaultDynamicTableData
    const iconsClassNames = `h-5 w-5 mx-1 cursor-pointer`

    const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
    const elementDesign = useElementDesign(data.id, data)
    const [clonedData, setSetData] = useState(_.cloneDeep(data));
    const elementContext = useContext(ElementContext);
    const { isPreviewMode, isFormMode } = useContext(DialogModes);

    const isPreviewOrFormMode = isPreviewMode || isFormMode; 

    const handleChanges = async (routeChanges, value) => {
        const name = routeChanges
        const type = "text"
        const customEventObject = {
            target: {
                name: name,
                value
            }
        };

        await elementContext.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)

        setProperty(clonedData, name, value, type)
        setSetData(clonedData => ({
            ...clonedData
        }));
    };

    const onAddRow = () => {
        const buildAddRowObject = Object.assign([], clonedData.dynamicListValues.listValues);
        const position = buildAddRowObject.length + 1;

        buildAddRowObject.push({
            position,
            value: `Value ${position}`
        })

        handleChanges("dynamicListValues.listValues", buildAddRowObject)
    }

    const onDeleteRow = async (index) => {
        const buildAddRowObject = Object.assign([], clonedData.dynamicListValues.listValues);

        buildAddRowObject.splice(index, 1)
        buildAddRowObject.map(((rowObject, rowIndex) => {
            return buildAddRowObject[rowIndex] = Object.assign({}, { position: rowIndex, value: buildAddRowObject[rowIndex].value });
        }))

        await handleChanges("dynamicListValues.listValues", buildAddRowObject)
    }

    const handleCellChange = async (index, editedObject) => {
        const listValuesToEdit = Object.assign([], clonedData.dynamicListValues.listValues);
        listValuesToEdit[index] = {...listValuesToEdit[index], ...editedObject};

        await handleChanges(`dynamicListValues.listValues`, listValuesToEdit)
    }

    const elementStyle = useElementStyle(data);
    const { width, ...borderStyle} = elementStyle.borderAndWidthStyle();
    const roundedCorners = data?.roundedCorners ? 'sm:rounded-lg' : '';
    const tableStyle = { ...borderStyle }
    
    const labelStyle = elementStyle.textStyle(data?.labelStyle);
    const labelClassName = elementStyle.textClassName(data?.labelStyle)
    
    const textStyle = elementStyle.textStyle(data?.text);
    const textClassName = elementStyle.textClassName(data?.text)

    const valueWithTranslation = (field) => {
        const translatedData = elementDesign.translateTerm(clonedData.dynamicListValues, "dynamicListValues")

        return translatedData[field];
    }

    return (
        <InlineActionbarElementWrapper designElement={elementDesign}>
            <div className={`${elementDesign.elementsClasses()} flex flex-col`}>
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div 
                            className={`flex overflow-hidden border-b border-gray-200 ${roundedCorners}`}
                            style={tableStyle}
                        >
                            <table id="resizeMe" className="table-fixed min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            key={"dynamicList-title"}
                                            scope="col"
                                            className="relative px-6 py-3 uppercase tracking-wider"
                                            style={labelStyle}
                                        >
                                            <div className={`flex justify-between items-center ${isPreviewOrFormMode ? labelClassName : ''}`}>
                                                {isPreviewOrFormMode ?
                                                    valueWithTranslation("title")
                                                : <input
                                                    className={`w-full overflow-ellipsis uppercase tracking-wider ${labelClassName}`}
                                                    name="title"
                                                    value={clonedData.dynamicListValues.title}
                                                    onChange={(e) => handleChanges("dynamicListValues.title", e.target.value)}
                                                />
                                            }
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        clonedData.dynamicListValues.listValues.map((listValue, index) => {
                                            const { position, value } = listValue;

                                            return (
                                                <tr key={position}>
                                                    <td
                                                        className={`px-6 py-4 whitespace-nowrap`}
                                                    >
                                                        <div className='flex justify-between items-center'>
                                                            {isPreviewOrFormMode ?
                                                                <div
                                                                    style={textStyle} 
                                                                    className={textClassName}>
                                                                    {valueWithTranslation(`listValues`)[index]?.value || ""}
                                                                </div>
                                                                : <><input
                                                                    style={textStyle}
                                                                    className={`w-full overflow-ellipsis ${textClassName}`}
                                                                    name="value"
                                                                    value={value || ""}
                                                                    onChange={(e) => handleCellChange(index, { position, value: e.target.value })}
                                                                />
                                                                    {!isPreviewOrFormMode &&
                                                                        <TrashIcon
                                                                            className={`${iconsClassNames}`}
                                                                            onClick={() => onDeleteRow(index)}
                                                                        />
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {!isPreviewOrFormMode &&
                            <div className='flex items-center'>
                                <div className='flex-grow bg-blue-500 h-1' />
                                <div className='flex-grow-0 text-center'>
                                    <PlusButton
                                        onClick={() => onAddRow()}
                                    />
                                </div>
                                <div className='flex-grow bg-blue-500 h-1' />
                            </div >
                        }
                    </div>
                </div>
            </div>
        </InlineActionbarElementWrapper>
    )
}
