import { useHeadings } from "../../hooks/useHeadings";
import { useScrollSpy } from "../../hooks/useScrollSpy";
import './styles.scss'

export const TableOfContent = ({elementsToTableInfo}) => {
  const headings = useHeadings(elementsToTableInfo);
    const activeId = useScrollSpy(
        headings.map(({ id }) => id),
      { rootMargin: "0% 0% 25% 0%" }
    );

    const isHeading = (element, tagName, className) => {
      return element?.tagName === tagName || element.className.includes(className)
    }

    return (
        <nav className="toc block w-48">
          <h2>Table of content</h2>
          <ul className="overflow-hidden">
            {headings.map((heading) => (
              <li key={heading.id} 
                className={`break-words my-2 text-xs ${isHeading(heading, "H2", "h2") ? "ml-1" : ""} ${isHeading(heading, "H3", "h3") ? "ml-2" : ""}`}
              >
                <a
                  style={{
                    marginLeft: `${heading.level - 2}em`,
                    fontWeight: activeId === heading.id ? "bold" : "normal"
                  }}
                  href={`#${heading.id}`}
                >
                 {isHeading(heading, "H2", "h2") || isHeading(heading, "H3", "h3")  ? "- " : ""} {heading?.tagName !== "TEXTAREA" ? heading.textContent : heading.value}
                </a>
              </li>
            ))}
          </ul>
        </nav>
    );
  }