import React from 'react'
import { ColumnContext, ContainerLockedContext } from "../contexts";
import { Locked } from '../structures/Column/locked';
import Properties from '../structures/Column/properties';

export const useColumnDesign = (columnId, data) => {
  const columnContext = React.useContext(ColumnContext);
  const containerLockedContext = React.useContext(ContainerLockedContext);

  const isContextActive = columnContext && columnContext.enabled
  const isLocked = containerLockedContext.isLocked
  return {
    onClick: (evt) => {
      if (isContextActive) {
        try {
          // prevent default event behavior if there is any
          evt.preventDefault()
        }
        catch (err) {
          // do nothing
        }

        if (isLocked) {
          columnContext.showPropertiesPanel(columnId, data, isLocked, (<Locked />));
        }
        else {
          columnContext.showPropertiesPanel(columnId, data, isLocked, (<Properties initData={data} actions={columnContext.actions} />));
        }
      }
    },
    isActive: () => {
      return isContextActive;
    },
    isSelected: () => {
      return columnContext?.currentColumnId === columnId;
    },
    columnsClasses: () => {
      let classes = ''

      if (columnContext?.showGrid) {
        classes = 'grid-column '
      }

      const isNew = columnContext?.newColumnId === columnId;

      if (isContextActive || isNew) {
        const isSelected = columnContext?.currentColumnId === columnId;
                
        classes += 'design-column pointer ';
        classes += isSelected ? ' active ' : ''
        classes += isNew ? ' new ' : ''
        classes += ` open-column-actionbar-${columnId}`
      }

      return classes
    },
    hasContext: () => {
      return isContextActive;
    }
  }
}