import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import {
  RecoilRoot,
} from 'recoil';
import { AuthenticationProvider, oidcLog, WebStorageStateStore, useReactOidc } from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import { config, oidcConfig } from './utils/config';
import AdminLayout from "./layouts/AdminLayout"
import Oidc from "./screens/Oidc";
import DialogDevelopment from "./screens/DialogDevelopment";
import { Toaster } from './toaster';
import { OidcSecure } from '@axa-fr/react-oidc-context'
import { Unauthorized } from './screens/Errors/Unauthorized';
import { NotFound } from './screens/Errors/NotFound';
import { setAuthHeader } from './api/api'
import { MissingLicense } from './screens/Errors/MissingLicense';
import { getUserProfile } from './api/userProfile';
import { OidcRoutesContext } from './contexts';
import { getCustomerInfo } from './api/customer';
import { useToastAction } from './hooks/useToastAction';
import { useFeature } from './hooks/useFeature';

import { AlertModal } from 'metaforce-core';

import ErrorLayout from './layouts/ErrorLayout';
import ListSmartForms from './screens/EmbeddedInternal/ListSmartForms';
import ListValuesOnForm from './screens/EmbeddedInternal/ListValuesOnForm';

function App() {
  const publicRoutes = [
    "/unauthorized",
    "/missing-license",
    "/dialog",
    "/sign",
  ]

  useEffect(() => {
    document.title = config.appTitle;
    const faviconEl = document.getElementById("favicon");
    faviconEl.href = config.favicon;
  }, [])


  const isPublicRoute = publicRoutes.some(path => window.location.pathname.startsWith(path));

  return (
    <div id="app" className="app h-screen">
      <RecoilRoot>
        <AlertModal>
          <Router>
            {
              isPublicRoute ?
                (
                  <Routes>
                    <Route exact={true} path="/unauthorized" element={<ErrorLayout><Unauthorized /></ErrorLayout>} />
                    <Route exact={true} path="/missing-license" element={<ErrorLayout><MissingLicense /></ErrorLayout>} />
                    <Route exact={true} path="/not-found" element={<ErrorLayout><NotFound /></ErrorLayout>} />
                  </Routes>
                )
                :
                (
                  <AuthenticationProvider
                    configuration={oidcConfig}
                    loggerLevel={oidcLog.ERROR}
                    isEnabled={true}
                    UserStore={WebStorageStateStore}
                    callbackComponentOverride={Oidc}
                    notAuthenticated={() => { return (<h1>Not Authenticated.</h1>) }}
                    notAuthorized={() => { return (<h1>Not Authorized.</h1>) }}
                    authenticating={Oidc}
                  >
                    <OidcSecure>
                      <SetAuthToken>
                        <SetOidcRoutesContext>
                          <Routes>
                            {/* Demo / test routes */}
                            <Route exact={true} path="/" element={
                              <AdminLayoutRoute nav="embeddedinternal" component={ListSmartForms} featureEnabled={true} />
                            } />


                            <Route exact={true} path="/embeddedinternal" element={
                              <AdminLayoutRoute nav="embeddedinternal" component={ListSmartForms} featureEnabled={true} />
                            } />

                            <Route exact={true} path="/embeddedinternal/:dialogKey" element={
                              <AdminLayoutRoute nav="embeddedinternal" subMenuNav="embeddedinternal" component={ListValuesOnForm} featureEnabled={true} />
                            } />

                          </Routes>
                        </SetOidcRoutesContext>
                      </SetAuthToken>
                    </OidcSecure>
                  </AuthenticationProvider>
                )
            }
          </Router>
        </AlertModal>
        <Toaster />
      </RecoilRoot>
    </div>
  )
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  requiredFeatureCode,
  ...rest
}) {

  const feature = useFeature();

  return (
    <ProtectedRoute featureEnabled={featureEnabled} hasFeature={feature.hasFeature(requiredFeatureCode)} >
      <AdminLayout nav={nav} subMenuNav={subMenuNav} {...rest} >
        <Component {...rest} />
      </AdminLayout>
    </ProtectedRoute>
  )
}

const ProtectedRoute = ({ featureEnabled, hasFeature, children }) => {
  return featureEnabled && hasFeature ? (
    <>
      {children}
    </>
  ) :
    (<Navigate to={'/'} />)
}

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState();

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const res = await Promise.all([userProfilePromise, customerPromise]);
      setUserProfile(res[0]);
      setCustomerInfo(res[1]);

    }, "Failed to load user data");
  }

  useEffect(() => {
    loadUserContextData();
  }, []);

  return !loadAction.isExecuting && (
    <OidcRoutesContext.Provider
      value={{
        userProfile,
        customerInfo
      }}>
      {customerInfo && children}
    </OidcRoutesContext.Provider>
  )
}

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header 
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token)

  useEffect(() => {
    if (!events) return
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events])

  const addUserSignedOut = () => {
    const userManager = getUserManager()
    userManager.removeUser();
    window.location.reload(false);
  }

  return (
    <>
      {children}
    </>
  );
}

export default App;
