import { useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">
      <Property.Group
        title='Information'>

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Text'}
          name={'label'}
          value={data.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Max size in mb"}
          name={"maxFileSizeInMb"}
          type={'number'}
          maxNumber={10}
          value={data?.maxFileSizeInMb || 10}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data.text.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          value={data?.text?.size}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Description'}
          name={'placeholder'}
          value={data?.placeholder}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Required field"}
          name={"requiredField"}
          value={data?.requiredField}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />

      </Property.Group>

      <Property.Group title="Element Style">

        <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

      </Property.Group>

    </div>
  )
}
