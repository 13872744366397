import React, { useState } from 'react'
import * as _ from 'lodash'
import { FormContext } from '../../contexts'

import './styles.scss'
import { useElementDesign } from '../useElementDesign'

export default function RadioButtons({ data, ...props }) {
  let inputType = 'text'

  const classPrefix = "inputTextLine"

  const colorClass = data?.text?.color ? `${classPrefix}--color-${data.text.color}` : ''
  const textAlignClass = data?.text?.textAlign ? `${classPrefix}--textalign-${data.text.textAlign}` : ''
  const boldClass = data?.text?.bold ? `${classPrefix}--bold` : ''
  const italicClass = data?.text?.italic ? `${classPrefix}--italic` : ''
  const underlineClass = data?.text?.underline ? `${classPrefix}--underline` : ''
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit'
  const fontSizeStyle = data?.text?.size ? data.text.size : 'inherit'
  const fontFamilyStyle = data?.text?.fontFamily ? `${data.text.fontFamily}, Arial, sans-serif` : `Arial, sans-serif`
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'
  const marginStyle = data?.margin ? data.margin : 'inherit'

  const labelStyle = {
    fontSize: fontSizeStyle,
    fontFamily: fontFamilyStyle
  }

  const radioButtonStyle = {
    fontSize: fontSizeStyle,
    fontFamily: fontFamilyStyle
  }

  const [value, setValue] = useState('');
  const formContext = React.useContext(FormContext);

  let currentValue;
  let onChange;
  if (formContext) {
    currentValue = formContext?.inputValues[data.id]?.value;
    onChange = (e, item) => {
      formContext.updateValue(data.id, item)
    }
  }
  else {
    currentValue = value;
    onChange = (e, item) => {
      setValue(item);
    }
  }
  const elementDesign = useElementDesign(data.id);

  return (
    <div
      className={data?.labelTop ? `${elementDesign.elementsClasses()}` : `sm:grid sm:grid-cols-2 sm:gap-6 sm:items-start ${elementDesign.elementsClasses()}`}
      onClick={elementDesign.onClick}
    >
      {
        data.label &&
        <label style={labelStyle} className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
          {data.label}
        </label>
      }
      <fieldset className="mt-2 sm:mt-0 max-w-xl w-full" id={data.property}>
        <div className="mt-2 space-y-4">
          {
            data.options.map((item, index) => {
              return (
                <div className="flex items-center" key={`${data.property}-element-${index}`}>
                  <input
                    id={`${data.property}-element-${index}`}
                    name={data.property}
                    type="radio"
                    className="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 border-gray-300"
                    checked={currentValue === item}
                    onChange={(e) => onChange(e, item)}
                  />
                  <label
                    htmlFor={`${data.property}-element-${index}`}
                    className="ml-3 block text-gray-700"
                    style={radioButtonStyle}
                  >
                    {item}
                  </label>
                </div>
              )
            }
            )
          }
        </div>
      </fieldset>
    </div>
  )
}
