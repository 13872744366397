import { get, put, post, destroy } from './api'


export const getDialogDefinitions = async ({ folderId, inTrash } = {}) => {
  const parameters = inTrash ? "?inTrash=true" : `${folderId ? `?folderId=${folderId}` : ""}`;
  return await get(`dialogDefinitions/list${parameters}`,);
}

export const getActiveDialogDefinitions = async () => {
  return await get('dialogDefinitions/active/list');
}

export const getDialogDefinitionsOnCompany = async (companyId) => {
  return await get(`dialogDefinitions/company/${companyId}`);
}

/**
 * Fetches the currently published dialog definition 
 */
export const getDialogDefinition = async (id) => {
  return await get(`dialogDefinitions/${id}`);
}

/**
 * Fetches the draft mode of the specified dialog definition. 
 * Falls back to the currently published record if there is no draft recrord 
 */
export const getDialogDefinitionEditMode = async (id) => {
  return await get(`dialogDefinitions/${id}/editMode`);
}

export const getDialogDefinitionSchema = async () => {
  return await get('dialogDefinitions/schema');
}

export const getDialogDefinitionElements = async (id) => {
  return await get(`dialogDefinitions/${id}/elements`);
}

export const getDialogDefinitionVersions = async (id) => {
  return await get(`dialogDefinitions/${id}/versions`);
}

export const getDefaultDialogDefinition = async () => {
  return await get(`dialogDefinitions/default`);
}

export const rollbackDialogDefinition = async (id, versionId, replaceDraft) => {
  return await post(`dialogDefinitions/${id}/versions/${versionId}/rollback`, {
    replaceDraft,
  });
}

/**
 * Updates an entire DialogDefinition
 * @param {json object} dialogDefinition - full json representation of a dialog defintion 
 */
export const updateDialogDefinition = async (dialogDefinition) => {
  return await put('dialogDefinitions', dialogDefinition);
}

/**
 * Updates some properties of a DialogDefinition
 * @param {*} id 
 * @param {*} dialogDefinition  
 */
export const updateDialogDefinitionProperties = async (id, dialogDefinition) => {
  return await put(`dialogDefinitions/${id}`, dialogDefinition)
}

export const updateDialogDefinitionProcess = async (id, process) => {
  return await put(`dialogDefinitions/${id}/process`, { process })
}

export const duplicateDialogDefinition = async (id) => {
  return await post(`dialogDefinitions/${id}/duplicate`);
}

export const createDefaultDialogDefinition = async (name) => {
  return await post(`dialogDefinitions/default`, { name });
}

export const createDialogDefinitionFromTemplate = async (template) => {
  return await post(`dialogDefinitions/template`, template);
}

export const publishDialogDefinition = async (id) => {
  return await put(`dialogDefinitions/${id}/publish`)
}

export const activateDialogDefinition = async (id) => {
  return await put(`dialogDefinitions/${id}/activate`)
}

export const deactivateDialogDefinition = async (id) => {
  return await put(`dialogDefinitions/${id}/deactivate`)
}

export const searchDialogDefinitions = async (tag) => {
  return await get(`dialogDefinitions/search?tag=${tag}`)
}

export const deleteDialogDefinition = async (id) => {
  return await destroy(`dialogDefinitions/${id}`)
}

export const getDialogDefinitionFolders = async () => {
  return await get(`dialogDefinitionFolders`)
}

export const createDialogDefinitionFolder = async (dialogDefinitionFolder) => {
  return await post(`dialogDefinitionFolders`, dialogDefinitionFolder);
}

export const deleteDialogDefinitionFolder = async (dialogDefinitionFolder) => {
  return await destroy(`dialogDefinitionFolders/${dialogDefinitionFolder}`)
}

export const addFolderToDialogDefinition = async (folderToDialogDefinition) => {
  return await post(`dialogDefinitions/addFolderToDialogDefinition`, folderToDialogDefinition);
}

export const removeFolderToDialogDefinition = async (folderToDialogDefinition) => {
  return await post(`dialogDefinitions/removeFolderToDialogDefinition`, folderToDialogDefinition);
}