import { useContext, useRef } from 'react'
import { useElementDesign } from '../useElementDesign'
import { FormContext } from '../../contexts'
import { Signature } from "../../components/Signature"
import { useMultiStep } from '../../hooks/useMultiStep'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'

export default function SignatureElement({ data, process, ...props }) {
  const formContext = useRef()
  const multiStep = useMultiStep(data);
  formContext.current = useContext(FormContext);

  const { textStyle } = useElementStyle(data);
  const elementDesign = useElementDesign(data.id, data)
  const labelStyle = textStyle(data?.labelStyle)


  const onUpdate = (newValue) => {
    if (formContext.current) {
      formContext.current.updateValue(data.id, newValue)
    }
  }

  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, formContext.current?.inputValues[data.id]?.value)

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit'
  const heightStyle = data?.height ? `${data.height}px` : 'auto'

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <Signature
        id={data.id}
        name={data.placeholder}
        label={elementDesign.translateTerm(data.value)}
        labelStyle={labelStyle}
        helpTooltip={elementDesign.translateHelpText(data)}
        value={formContext.current?.inputValues[data.id]?.value}
        readOnly={elementDesign.isReadOnly() || multiStep.isReadOnly}
        onClick={elementDesign.onClick}
        onBlur={elementDesign.handleBlur}
        onUpdate={onUpdate}
        required={data.requiredField}
        borderWidthStyle={borderWidthStyle}
        borderColorStyle={borderColorStyle}
        heightStyle={heightStyle}
        containerClassName={`${elementDesign.elementsClasses()} ${elementDesign.readOnlyElementClasses()}`}
        canvasClassName={inputErrorClasses ? inputErrorClasses : null}
        data={data}
      />
    </InlineActionbarElementWrapper>
  )
}