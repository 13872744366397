import { useState, useContext } from 'react'
import { FormContext } from '../../contexts'
import 'react-day-picker/dist/style.css';
import './styles.scss'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { getHours, getMinutes, timeHasValueSet } from "../../utils/elements"
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';

export default function TimePicker({ data, ...props }) {
  const formContext = useContext(FormContext);
  const [value, setValue] = useState();
  const multiStep = useMultiStep(data);

  function onSelected(e) {
    setValue(e)
    formContext?.updateValue(data.id, e)
  }

  const classPrefix = "datePicker"
  const elementStyle = useElementStyle(data);
  const { color, fontSize, fontFamily} = elementStyle.textStyle(data?.text);

  const labelStyle = elementStyle.textStyle(data?.labelStyle, data?.text?.alignment);

  const borderStyle = elementStyle.borderAndWidthStyle();

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'

  const inputWrapperStyle = {
    ...borderStyle,
    color,
    backgroundColor: backgroundColorStyle,
  }

  const textStyle = {
    fontFamily,
    fontSize,
    color
  }

  const val = formContext?.inputValues[data.id]?.value
    ? formContext?.inputValues[data.id]?.value
    : value

  const updateValue = (hours, minutes) => {
    const hoursString = hours != null ? hours.toString().padStart(2, '0') : null
    const minuteString = minutes != null ? minutes.toString().padStart(2, '0') : null
    formContext?.updateValue(data.id, `${hoursString}:${minuteString}`)
  }
  const isPm = (value) => {
    if (!value) {
      return false
    }
    const hours = getHours(value)
    return hours >= 12
  }

  const onHoursUpdate = (e) => {
    let hours = parseInt(hoursSelectValueToValue(e.target.value))
    if (isPm(val)) {
      hours += 12
    }
    updateValue(hours, getMinutes(val))
  }

  const onMinutesChange = (e) => {
    let newMinutesValue = minutesSelectValueToValue(e.target.value)

    updateValue(getHours(val), newMinutesValue)
  }

  const onAmPmChange = (e) => {
    let newAmPmValue = e.target.value
    const currentHours = getHours(val)
    let hours = currentHours
    if (newAmPmValue === 'pm' && !isPm(val)) {
      hours += 12
    }
    else if (newAmPmValue === 'am' && isPm(val)) {
      hours -= 12
    }

    updateValue(hours, getMinutes(val))
  }

  const hoursSelectValueToValue = (hoursValue) => {
    return hoursValue === '12' ? '0' : hoursValue
  }

  const hoursValueToSelectValue = (hoursValue) => {
    if (hoursValue === 0) {
      return 12
    }
    else if (hoursValue > 12) {
      return hoursValue - 12
    }
    return hoursValue
  }

  const minutesValueToSelectValue = (minutesValue) => {
    if (minutesValue === 45) {
      return 'quarter-to'
    }
    else if (minutesValue === 30) {
      return 'half'
    }
    else if (minutesValue === 15) {
      return 'quarter-past'
    }
    else if (minutesValue === 0) {
      return 'hour'
    }
    return minutesValue
  }

  const minutesSelectValueToValue = (minutesSelectValue) => {
    if (minutesSelectValue === 'quarter-to') {
      return 45
    }
    else if (minutesSelectValue === 'half') {
      return 30
    }
    else if (minutesSelectValue === 'quarter-past') {
      return 15
    }
    else if (minutesSelectValue === 'hour') {
      return 0
    }
    return minutesSelectValue
  }

  const elementDesign = useElementDesign(data.id, data);
  const selectValueHours = hoursValueToSelectValue(getHours(val))
  const selectValueMinutes = minutesValueToSelectValue(getMinutes(val))
  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={data?.labelTop ? `${elementDesign.elementsClasses()}` : `sm:grid sm:grid-cols-2 sm:gap-6 sm:items-start ${elementDesign.elementsClasses()}`}
        onClick={elementDesign.onClick}
        onBlur={elementDesign.handleBlur}
      >
        {
          data.label &&
          <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2`}>
            {elementDesign.translateTerm(data.label)}
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            {elementDesign.translateHelpText(data)}
          </label>
        }
        {
          !data.label &&
          <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2`}>
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            {elementDesign.translateHelpText(data)}
          </label>
        }
        <div
          style={inputWrapperStyle}
          className={`mt-2 p-1 bg-white ${roundedCornerClass} ${elementDesign.inputErrorClasses(data.requiredField, timeHasValueSet(val) ? 'value' : null)}`}
        >
          <div className="">
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            <select
              name="hours"
              className={`border-0 bg-transparent text-sm appearance-none outline-none h-10 ${elementDesign.readOnlyElementClasses()}`}
              value={selectValueHours}
              onChange={onHoursUpdate}
              style={textStyle}
              disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
            >
              {
                !selectValueHours && <option>--</option>
              }
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <span className="text-sm mr-3">:</span>
            <select
              name="minutes"
              className={`border-0 bg-transparent text-sm appearance-none outline-none mr-4 h-10 ${elementDesign.readOnlyElementClasses()}`}
              value={selectValueMinutes}
              onChange={onMinutesChange}
              style={textStyle}
              disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
            >
              {
                selectValueMinutes == null && <option>--</option>
              }
              <option value="hour">00</option>
              <option value="quarter-past">15</option>
              <option value="half">30</option>
              <option value="quarter-to">30</option>
            </select>
            <select
              name="ampm"
              className={`border-0 bg-transparent text-sm appearance-none outline-none ${elementDesign.readOnlyElementClasses()}`}
              value={isPm(val) ? 'pm' : 'am'}
              onChange={onAmPmChange}
              style={textStyle}
              disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
            >
              <option value="am">AM</option>
              <option value="pm">PM</option>
            </select>
          </div>
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}
