import { useState } from 'react';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';


export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0 px-4 sm:px-6">

      <Property.Group
        title='Information'>

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Height'}
          name={'height'}
          value={data.height}
        />

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Width'}
          name={'width'}
          value={data.width}
        />

      </Property.Group>
    </div>
  )
}
