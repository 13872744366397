import Enumerable from 'linq';

import { dialogDefinitionStatuses, processTypes } from "./constants"

export const isActive = (dialogDefinition) => {
  return dialogDefinition.status !== dialogDefinitionStatuses.inactive;
}

export const isContainerLocked = (containerDefinition) => {
  return !!containerDefinition.dialogObjectId && containerDefinition.lockToDialogObject;
}

export const getAllElements = (dialogDefinition) => {
  return Enumerable.from(dialogDefinition?.containers)
    .selectMany(c => c.rows)
    .selectMany(r => r.columns)
    .selectMany(c => c.elements)
    .toArray();
}

export const isMultiStep = (dialogDefinition) => {
    return dialogDefinition.process === processTypes.multiStep;
}