import { useRef, useContext } from 'react'
import { Button } from '../../components/Form/Button';
import { DialogModes, FormContext } from '../../contexts'
import { DownloadIcon, TrashIcon } from '@heroicons/react/solid'
import './styles.scss'
import toast from 'react-hot-toast';
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import { MegaByte } from '../../utils/constants';
import { saveAs } from 'file-saver';

export default function FileUpload({ data, ...props }) {
  const formContext = useContext(FormContext);
  const { isEditMode } = useContext(DialogModes);
  const elementDesign = useElementDesign(data.id, data);
  const elementStyle = useElementStyle(data);
  const inputFile = useRef(null)
  const multiStep = useMultiStep(data);
  const filesUploadedVerification = formContext?.inputValues[data.id]?.files?.length > 0;

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  let { borderColor } = elementStyle.borderAndWidthStyle()
  const marginStyle = data?.margin ? data.margin : 'inherit'
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'
  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, filesUploadedVerification ? 'hasvalue' : null)
  const style = {
    margin: marginStyle,
    borderColor: !inputErrorClasses ? borderColor : undefined,
    backgroundColor: backgroundColorStyle,
  }

  let { color, fontSize } = elementStyle.textStyle(data?.text);
  const textStyle = {
    color: color,
    fontSize
  }

  const onChange = (evt) => {
    if (formContext) {
      const file = evt.target.files[0];

      // hard limit of 10 MB
      const maxMbSize = data.maxFileSizeInMb
        ? Math.min(Math.max(data.maxFileSizeInMb, 0), 10)
        : 10;
      if (file.size > (MegaByte * maxMbSize)) {
        toast.error("File is too large", {
          position: "bottom-center"
        })
      }
      else {
        formContext.uploadFile(data.id, evt.target.files[0]);
      }
    }
  }

  const handOpenFileDialog = (evt) => {
    if (elementDesign.isReadOnly() || isEditMode) {
      return false
    }
    // `current` points to the mounted file input element
    inputFile.current.click()
  };

  const onDelete = (evt, elementId, fileId) => {
    formContext.deleteFile(evt, elementId, fileId)
  }

  const onDownload = (evt, elementId, fileId) => {
    evt.preventDefault()
    
    let fileToDownload = formContext?.inputValues[data.id]?.files?.find(file => file.id === fileId)

    console.log(fileToDownload)

    if(fileToDownload)
      saveAs(`data:${fileToDownload.contentType};base64,${fileToDownload.content}`, fileToDownload.filename)
  }

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={`mt-1 flex flex-col px-6 pt-5 pb-6 border-2 ${roundedCornerClass} ${inputErrorClasses ? inputErrorClasses : 'border-dashed'}`}
        style={style}
      >
        <div
          className={`space-y-1 text-center justify-center cursor-pointer ${elementDesign.readOnlyElementClasses()}`}
          onClick={handOpenFileDialog}
        >
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div
            className="flex justify-center text-sm text-gray-600"
            onBlur={elementDesign.handleBlur}
          >
            <label
              className={`relative ${elementDesign.isReadOnly() ? '' : 'cursor-pointer'} bg-white rounded-md font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-400`}
            >
              <span style={textStyle} className={"underline"}>
                {elementDesign.translateTerm(data.label ? data.label : 'Upload file')}
                {elementDesign.requiredFieldIndicator(data.requiredField)}
                {elementDesign.translateHelpText(data)}
              </span>

            </label>
            {
              !elementDesign.isReadOnly() &&
              <input
                ref={inputFile}
                id={data.property}
                type="file"
                className="sr-only"
                onChange={onChange}
                disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
              />
            }
          </div>
          <p className="text-xs text-gray-500">
            {elementDesign.translateTerm(data.placeholder, 'description')}
          </p>
        </div>
        <div className="">
          {
            filesUploadedVerification &&
            <div className="">
              <div className="w-full border-t border-gray-300 my-5" />
              <span style={textStyle}>Files uploaded</span>
              <div>
                {
                  formContext?.inputValues[data.id]?.files?.map(f => {
                    return (
                      <div className="flex justify-between" key={f.id}>
                        <span className="text-xs text-gray-500 self-center">{f.filename}</span>
                        <div className="ml-1 mr-4 grid grid-cols-2 gap-2">
                        <Button
                            theme={"text"}
                            icon={DownloadIcon}
                            onClick={(evt) => onDownload(evt, data.id, f.id)}
                          />
                          <Button
                            theme={"text"}
                            icon={TrashIcon}
                            onClick={(evt) => onDelete(evt, data.id, f.id)}
                            disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          }
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}