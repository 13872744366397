export const adminProductId = "611b8ac465064876fb35e1cf";
export const designFeatureCode = "DSF";
export const dataFeatureCode = "DDF";
export const templateFeatureCode = "DTF";

export const designContexts = {
  element: 'element',
  row: 'row',
  column: 'column',
  container: 'container',
}

export const editStates = {
  published: 0,
  draft: 1
}

export const dataLoadStates = {
  imported: 1,
  dialogsGenerated: 5,
  active: 10,
  notfied: 11
}
export const dataLoadNotificationStates = {
  notSet: 1,
  draft: 5,
  issued: 10
}

export const dataLoadTypes = {
  localFile: 1,
  ftp: 2,
  api: 3,
  crm: 4
}

export const elementTypes = {
  textLine: 0,
  textArea: 1,
  inputTextLine: 2,
  headerLine: 3,
  bitmatp: 4,
  datePicker: 5,
  button: 6,
  switch: 7,
  divider: 8,
  radioList: 9,
  table: 10,
  radioListAsGroup: 11,
  fileUpload: 12,
  radioButtons: 13,
  disclaimer: 14,
  dropDown: 15,
  flowSelector: 16,
  checkbox: 17,
  cancelButton: 18,
  signature: 19,
  timePicker: 20,
  attachment: 21,
  video: 22,
  hyperLink: 23,
  richText: 24,
  dynamicTable: 25,
  dynamicList: 26,
  radioButtonGroup: 27,
  spacer: 28
}

export const dialogDefinitionStatuses = {
  active: 0,
  inactive: 1,
}

export const dialogObjectStatuses = {
  active: 0,
  inactive: 1,
}

export const webhookStatuses = {
  active: 0,
  inactive: 1,
}

export const webhookTypes = {
  manual: 0,
}

export const webhookSources = {
  smartForms: 0,
  zapier: 1,
}

export const securityType = {
  none: 1,
  email: 2,
  phone: 3,
  bankId: 4,
}

export const securityTypeOptions = [
  {
    value: securityType.none,
    name: 'None',
  },
  {
    value: securityType.email,
    name: 'Email',
  },
  {
    value: securityType.phone,
    name: 'Phone',
  },
  {
    value: securityType.bankId,
    name: 'BankId',
  },
]

export const processTypes = {
  normal: "Normal",
  multiStep: "Multi-Step",
}

export const processTypeOptions = [
  {
    value: processTypes.normal,
    name: "Normal",
  },
  {
    value: processTypes.multiStep,
    name: "Process",
  },
]

export const dialogValuesStepStatus = {
  notStarted: 1,
  inProgress: 2,
  completed: 3,
}

export const validateDataTypes = {
  text: 0,
  number: 1,
  email: 2,
  SSN: 3,
  Api: 4,
  BBAN: 5
}

export const validateDataTypeOptions = [
  {
    value: validateDataTypes.text,
    name: 'Text',
  },
  {
    value: validateDataTypes.number,
    name: 'Number',
  },
  {
    value: validateDataTypes.email,
    name: 'Email',
  },
  {
    value: validateDataTypes.SSN,
    name: 'SSN',
  },
  {
    value: validateDataTypes.Api,
    name: 'Api',
  },
  {
    value: validateDataTypes.BBAN,
    name: 'BBAN'
  }
]

export const countries = {
  none: 0,
  norway: 0,
  sweden: 1,
  finland: 2,
  denmark: 3
}

export const countriesOptions = [
  {
    value: countries.norway,
    name: 'Norway',
  },
  {
    value: countries.sweden,
    name: 'Sweden',
  },
  {
    value: countries.finland,
    name: 'Finland',
  },
  {
    value: countries.denmark,
    name: 'Denmark',
  },
];

export const templateLanguages = [{ value: "no", name: "Norsk" }, { value: "en", name: "English" }];

export const dependenciesType = {
  none: 0,
  selected: 1
}

export const dependenciesOptions = [
  {
    value: dependenciesType.none,
    name: 'None',
  },
  {
    value: dependenciesType.selected,
    name: 'Selected',
  },
]

export const notificationEventType = {
  notification: 1,
  securityCode: 2,
  processStep: 3,
  completedWithPdf: 4,
  reminder: 5,
}

export const authenticationTemplateStatus = {
  active: 10,
  disabled: 99
}

export const notificationTemplateStatus =
{
  active: 10,
  disabled: 99
}

export const MegaByte = 1048576

export const tableInputTypes =
{
  inputTextLine: 0,
  dropdown: 1
}

export const tableValueTypes =
{
  number: 0,
  percent: 1
}

export const dialogType = {
  normal: 1,
  showAndHide: 2,
  stepByStep: 3,
  standardWTable: 4
}

export const dialogTypeOptions = [
  {
    value: dialogType.normal,
    name: "Normal",
  },
  {
    value: dialogType.showAndHide,
    name: "Show And Hide"
  },
  {
    value: dialogType.stepByStep,
    name: "Step by step"
  },
  {
    value: dialogType.standardWTable,
    name: "Standard w/Table"
  }
]

export const positionTypes = {
  top: 0,
  left: 1
}

export const positionOptions = [
  {
    value: positionTypes.top,
    name: "Top",
  },
  {
    value: positionTypes.left,
    name: "Left"
  }
]

export const backgroundSizeTypes = {
  auto: 0,
  contain: 1,
  cover: 2,
}

export const backgroundSizeOptions = [
  {
    name: "Auto",
    value: backgroundSizeTypes.auto,
  },
  {
    name: "Contain",
    value: backgroundSizeTypes.contain,
  },
  {
    name: "Cover",
    value: backgroundSizeTypes.cover,
  }
]

export const dialogDefinitionReminderMethods = {
  email: 0,
  sms: 1
}

export const alignmentOptions = [
  { name: "Left", value: 'left' },
  { name: "Center", value: 'center' },
  { name: "Right", value: 'right' }
]

export const dialogSizeTypes = {
  small: 0,
  medium: 1,
  large: 2
}