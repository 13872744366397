import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { FormContext } from '../../contexts'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle';
import InlineEditing from '../../components/InlineEditing'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown({ data, ...props }) {
  const [selected, setSelected] = useState(-1)
  const formContext = React.useContext(FormContext);

  const elementDesign = useElementDesign(data.id, data);
  const multiStep = useMultiStep(data);
  const initialDisplayValue = elementDesign.translateTerm(data?.placeholder ? data?.placeholder : 'Select from dropdown', "placeholder")

  const classPrefix = "dropdown";
  let currentValue;
  let currentDisplayValue;
  let onChange;
  if (formContext) {
    currentValue = formContext?.inputValues[data.id]?.value || initialDisplayValue;
    onChange = (value) => {
      formContext.updateValue(data.id, value)
    }
  }
  else {
    currentValue = selected
    onChange = (value) => {
      setSelected(value);
    }
  }

  const match = data.optionWithValues?.find(d => d.value === currentValue)
  if (match) {
    currentDisplayValue = elementDesign.translateOption(match.id, match.display, "display")
  }
  else {
    currentDisplayValue = initialDisplayValue
  }

  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, currentValue === initialDisplayValue ? null : currentValue) // treat initialDisplayValue as null
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const marginStyle = data?.margin ? data.margin : 'inherit'
  const wrapperStyle = {
    margin: marginStyle
  }

  const { borderAndWidthStyle, textStyle, textClassName} = useElementStyle(data);
  const { borderColor, borderWidth } = borderAndWidthStyle();
  const { justifyContent, ...textStyleProps } = textStyle(data?.text)
  const labelStyle = textStyle(data?.labelStyle)

  const inputWrapperStyle = {
    borderWidth: !inputErrorClasses ? borderWidth : undefined,
    borderColor: !inputErrorClasses ? borderColor : undefined,
    ...textStyleProps
  }

  const { color } = textStyleProps;
  const selectorStyle = {
    color: color
  }

  const isLabelHidden = data?.hideLabel ?? false; 

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={data?.labelTop ? `` : `sm:grid sm:grid-cols-2 sm:gap-6 sm:items-start`}
      >
        <div className={`${isLabelHidden ? 'hidden' : ''}`}>
          <InlineEditing
            initData={data}
            style={labelStyle}
            classes={`block text-sm text-gray-700 sm:mt-px sm:pt-2 ${textClassName(data?.text)}`}
            name='label'
            id={`label-${data.id}`}
          >
            <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2 ${textClassName(data?.text)}`}>
              {elementDesign.translateTerm(data.label)}
              {elementDesign.requiredFieldIndicator(data.requiredField)}
              {elementDesign.translateHelpText(data)}
            </label>
          </InlineEditing>
        </div>
        <div
          className="mt-2 sm:mt-0 w-full"
          style={wrapperStyle}
          onBlur={elementDesign.handleBlur}
        >
          <Listbox value={currentValue} onChange={onChange} disabled={elementDesign.isReadOnly() || multiStep.isReadOnly || elementDesign.isReadOnlyDependency(data?.dependencies?.value, data?.dependencies?.type)}>
            {({ open }) => (
              <>
                <div className="mt-1 relative z">
                  <Listbox.Button
                    className={`bg-white relative w-full ${inputErrorClasses} ${roundedCornerClass} shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm`}
                    style={inputWrapperStyle}
                  >
                    <span className="block truncate">
                      {currentDisplayValue}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon style={selectorStyle} className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-40"
                    >
                      {data.optionWithValues?.map((option, index) => (
                        <Listbox.Option
                          key={option.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={option.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {elementDesign.translateOption(option.id, option.display, "display")}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}