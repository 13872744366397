import { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { FormContext } from "../../contexts"
import { ShowHideContext, DialogModes } from '../../contexts'
import * as Structures from "../../structures"
import './styles.scss';
import { AddActions } from '../Container/addActions';
import SimpleStepper from '../../components/SimpleStepper';
import { TableOfContent } from '../../components/TableOfContent';
import { dialogSizeTypes, dialogType, positionTypes } from '../../utils/constants';
import { SortableContext } from '@dnd-kit/sortable';
import { DndSortable } from '../../components/DragAndDrop/sortable';
import { DragOverlay } from '@dnd-kit/core';

export default function Dialog({ data, signatures, currentSignature, zoom, toggleView, scrollY, isPreviewMode = false, isEditMode = false, isPdfMode = false, isPreviewTemplateMode = false, isFormMode = false, ...props }) {
  const showAllContainers = !data || data.type === dialogType.normal || data.type === dialogType.standardWTable
  const isStandardWTable = data.type === dialogType.standardWTable;
  const dialogRef = useRef();
  const formContext = useRef()
  formContext.current = useContext(FormContext);
  const [activeContainer, setActiveContainer] = useState(0);
  const [nextContainer, setNextContainer] = useState(activeContainer + 1);
  const [startContainer, setStartContainer] = useState(0);
  const [stopContainer, setStopContainer] = useState(data.containers.length);
  const [elementsToTableInfo, setElementsToTableInfo] = useState([])
  const firstRender = useRef(true);

  useEffect(() => {
  if (firstRender.current) {
    firstRender.current = false;
      return;
    }  
  });
  
  
  useEffect(() => {
    let selectedRefs;
    if (isStandardWTable && isEditMode) {
      selectedRefs = dialogRef.current.querySelectorAll("textarea.h1, textarea.h2, textarea.h3")
    } else {
      selectedRefs = dialogRef.current.querySelectorAll("h1, h2, h3")
    }
    setElementsToTableInfo(Object.assign([], selectedRefs))
    return () => setElementsToTableInfo([])
  }, [data])

  const spaceClass = showAllContainers && data?.space ? `space-y-${data.space}` : ''
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'

  let widthClass = 'max-w-screen-md'

  switch (data?.size) {
    case dialogSizeTypes.small:
      widthClass = 'max-w-screen-md'
      break;
    case dialogSizeTypes.medium:
      widthClass = 'max-w-screen-lg'
      break;
    case dialogSizeTypes.large:
      widthClass = 'max-w-screen-xl pl-16 pr-24'
      break;
    case 3:
      widthClass = 'maw-w-screen-sm'
      break;
    default:
      widthClass = 'max-w-screen-md'
      break;
  }
  function goToNextContainer(e) {
    let goToContainer = 0;

    if (data.type === dialogType.showAndHide) {
      goToContainer = nextContainer;
      setNextContainer(nextContainer + 1)
    }
    else
      goToContainer = activeContainer + 1;

    if (nextContainer >= startContainer && nextContainer <= stopContainer)
      setActiveContainer(goToContainer);

    // let goToContainer = 0;

    // if(data.type == 2) {
    //   goToContainer = nextContainer;
    //   setNextContainer(nextContainer + 1)
    // }
    // else
    //   goToContainer = activeContainer + 1;


    // setActiveContainer(goToContainer);
  }

  const getActiveContainer = () => {
    return data?.containers?.filter(m => !m.static)[activeContainer]
  }

  function goToPrevContainer(e) {
    let goToContainer = activeContainer - 1;

    if (goToContainer >= startContainer && goToContainer <= stopContainer) {
      setNextContainer(goToContainer + 1)
      setActiveContainer(goToContainer);
    }
  }

  const stepByStepStyles = () => {
    const isPositionLeft = data?.position === positionTypes.left
    let marginLeft = {
      0: '0px',
      1: '30px',
      2: '130px'
    }

    return showAllContainers ?
      {}
      :
      {
        backgroundColor: getActiveContainer()?.backgroundColor,
        padding: '24px',
        marginLeft: `${isEditMode && isPositionLeft ? marginLeft[data?.size] : marginLeft[dialogSizeTypes.small]} ` 
      }
  }

  const roundedCornerStepByStepClass = getActiveContainer()?.roundedCorners ? 'rounded-md' : ''

  const style = {
    backgroundColor: backgroundColorStyle,
  }

  const steppers = useMemo(() => {
    return data.containers.filter(m => !m.static).map(container => ({ name: container.name || "" }))
  }, [data.containers])

  return (
    <ShowHideContext.Provider
      value={{
        goToNextContainer: (e) => {
          goToNextContainer(e);
        },
        goToPrevContainer: (e) => {
          goToPrevContainer(e);
        },
        setFlowSelectorStartAndStop: (start, stop) => {
          setNextContainer(start);
          setStartContainer(start);
          setStopContainer(stop);
        },
      }}>

      <DialogModes.Provider
        value={{
            isPreviewMode,
            isEditMode,
            isPdfMode,
            isPreviewTemplateMode,
            isFormMode,
            firstRender: firstRender.current
          }
        }
      >
      <div
        className={`sm:min-h-full w-full my-1 print:my-0 ${backgroundColorStyle ? "print:min-h-screen" : ''}`}
        style={style}
      >
        {
          isPdfMode &&
          <div className='bg-color-pdf' style={style}></div>
        }
        
        <div
          className={`container z-10 ${isStandardWTable && !isPreviewMode ? "" : "mx-auto"} p-10 flex flex-col print:max-w-full print:block ${isPreviewMode ? 'relative' : ''} ${toggleView && toggleView !== 'max-w-screen-md' ? toggleView : widthClass} ${isEditMode ? 'mt-6' : ''}`}
        >
          {
            data?.containers.filter(m => m.static).map((container, index) => {
              return( 
                container.static &&
                      <Structures.Container
                        showAllContainers={showAllContainers}
                        activeContainer={activeContainer}
                        totalContainers={data.containers.length - 1}
                        dialogData={data}
                        data={container.container ? container.container : container}
                        marginOverride={data.margin}
                        paddingOverride={data.padding}
                      />
              )
            })
          }

          <div 
            className={roundedCornerStepByStepClass} 
            style={stepByStepStyles()}
          >

              {
                !showAllContainers &&
                <>
                  <SimpleStepper steps={steppers} currentStep={activeContainer} position={data?.position} />
                </>
              }
              <div className={`flex flex-row relative`}>
                {
                  isStandardWTable &&
                  <div className={`pr-12`}>
                    <TableOfContent elementsToTableInfo={elementsToTableInfo} />
                  </div>
                }
                <div ref={dialogRef} className={`print:table min-w-full flex flex-col ${spaceClass}`}>
                  {
                    !data.containers.length &&
                    <AddActions />
                  }
                  {
                    !isEditMode &&
                    data.containers.filter(m => !m.static).map((container, i) =>
                      <div key={`container-${container.id}`}>
                        {
                          (showAllContainers || container.static || activeContainer === i || isPdfMode) &&
                          <Structures.Container
                            showAllContainers={showAllContainers}
                            activeContainer={activeContainer}
                            totalContainers={data.containers.length - 1}
                            dialogData={data}
                            data={container.container ? container.container : container}
                            marginOverride={data.margin}
                            paddingOverride={data.padding}
                          />
                        }
                      </div>
                    )
                  }
                  {
                    isEditMode &&
                    <SortableContext
                      id={`container-sortable-context-${data.id}`}
                      items={data?.containers.filter(m => !m.static).map(c => `container-${c.id}`)}
                    >
                      {
                        <div className={`flex flex-col ${spaceClass}`}>
                          {data?.containers.filter(m => !m.static).map((container, index) => {
                            return (
                              (showAllContainers || container.static || activeContainer === index) &&
                              <DndSortable
                                key={container.id}
                                type="container"
                                id={`container-${container.id}`}
                                dragHandle={true}
                                dragHandleTooltip='Drag container'
                              >
                                <Structures.Container
                                  showAllContainers={showAllContainers}
                                  activeContainer={activeContainer}
                                  totalContainers={data.containers.length - 1}
                                  dialogData={data}
                                  data={container.container ? container.container : container}
                                  marginOverride={data.margin}
                                  paddingOverride={data.padding}
                                />
                              </DndSortable>
                            )
                          })}
                        </div>

                      }
                    </SortableContext>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </DialogModes.Provider>
    </ShowHideContext.Provider>
  );
}
