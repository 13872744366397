import { useState } from "react";
import { setProperty } from "../../utils/property";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { dependenciesOptions } from '../../utils/constants';
import { getAllCheckboxesOptions } from "../../utils/elements";

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData));
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  // const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type);

    setProperty(data, name, value, type);
    setData((data) => ({
      ...data,
    }));
  };

  let checkboxOptions = getAllCheckboxesOptions(dialogDefinition.containers);

  return (
    <div className="absolute inset-0">

      <Property.Group title="Information">
        <Property.Text
          onChange={handleOnChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'label'}
          value={data.label}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Placeholder"}
          name={"placeholder"}
          value={data.placeholder}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Initial value"}
          name={"initialValue"}
          value={data.initialValue}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Required field"}
          name={"requiredField"}
          value={data?.requiredField}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />
      </Property.Group>

      <Property.Group
        title='Label Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'labelStyle.color'}
          initColor={data?.labelStyle?.color || '#000'}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'labelStyle.size'}
          type={'number'}
          value={data?.labelStyle?.size}
        />

      </Property.Group>

      <Property.Group  title="Element Style">
      <Property.Color
          onChange={handleOnChange}
          label={'Background'}
          name={'backgroundColor'}
          initColor={data.backgroundColor}
        />
        
        <Property.Text
          onChange={handleOnChange}
          label={"Lines"}
          name={"rows"}
          type={'number'}
          value={data?.rows || 4}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Label top"}
          name={"labelTop"}
          value={data?.labelTop}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Hide label"}
          name={"hideLabel"}
          value={data?.hideLabel}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

      </Property.Group>


      <Property.Group title='Dependencies'>

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Type'}
          name={'dependencies.type'}
          value={data?.dependencies?.type}
          options={dependenciesOptions}
        />

        { data?.dependencies?.type === 1 &&
          <Property.Select
            onChange={handleOnChange}
            label={'Checkbox list'}
            name={'dependencies.value'}
            value={data.dependencies?.value}
            options={checkboxOptions}
          />
        }

      </Property.Group>

      <Property.Group title="Text Style">
        <Property.Color
          onChange={handleOnChange}
          label={"Color"}
          name={"text.color"}
          initColor={data.text.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data.text?.alignment}
          options={[
            { name: "Left", value: 'left' },
            { name: "Center", value: 'center' },
            { name: "Right", value: 'right' }
          ]}
        />

      </Property.Group>

    </div>
  );
}
