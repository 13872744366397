import * as _ from 'lodash'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';

import './styles.scss'
import { tableValueTypes } from '../../utils/constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NumberInput({ data, row, valueChanged, inPercent, currentTable, ...props }) {
  const { tableValues } = data;

  
  function onChange(e) {
    var newValue = e.target.value.split(/\D/).join('');

    console.log('inPercent', inPercent);

    if(inPercent) {
      const valueToInt = parseInt(newValue, 10);      
      const sum = _.sumBy(currentTable, (r) => {
        return r.id === row.id ? valueToInt : parseFloat(r.value) || 0
      });


      if(sum > 100) return e.preventDefault();      
    }

    valueChanged(row.id, newValue)
  }

  const elementDesign = useElementDesign(data.id, data)
  const multiStep = useMultiStep(data);

  function paddingNumber(text) {
    let textLength = text.length

    if (textLength < 3)
      return 8;

    if (textLength < 6)
      return 10;

    if (textLength < 8)
      return 16;

    if (textLength < 13)
      return 24;

    if (textLength < 16)
      return 28;

    if (textLength < 20)
      return 32;

    return 40;
  }

  const classPrefix = "inputTextLine"

  let colorStyle = 'inherit'

  if (data?.text?.color && data?.text?.color.startsWith('#')) {
    colorStyle = data?.text?.color
  }

  const textAlignClass = data?.text?.textAlign ? `${classPrefix}--textalign-${data.text.textAlign}` : ''
  const boldClass = data?.text?.bold ? `${classPrefix}--bold` : ''
  const italicClass = data?.text?.italic ? `${classPrefix}--italic` : ''
  const underlineClass = data?.text?.underline ? `${classPrefix}--underline` : ''
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit'
  const fontSizeStyle = data?.text?.size ? data.text.size : 'inherit'
  const fontFamilyStyle = data?.text?.fontFamily ? `${data.text.fontFamily}, Arial, sans-serif` : `Arial, sans-serif`
  const leadingTextClass = data?.leadingText ? `pl-${paddingNumber(data.leadingText)}` : ''
  const trailingTextClass = data?.trailingText ? `pr-${paddingNumber(data.trailingText)}` : ''

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'transparent'
  const marginStyle = data?.margin ? data.margin : 'inherit'

  const inputWrapperStyle = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    backgroundColor: backgroundColorStyle,
    margin: marginStyle,
    color: colorStyle
  }

  const inputStyle = {
    fontFamily: fontFamilyStyle,
    fontSize: fontSizeStyle,
    backgroundColor: backgroundColorStyle,
    borderWidth: 0,
    color: colorStyle
  }

  const suffixWithTypeValidationAndTranslation = () => {
    return data.tableValueType === tableValueTypes.number ? 
    elementDesign.translateTerm(data.trailingText, 'trailingText') : "%"
  }

  return (
    <div className="max-w-xl w-full col-span-4 justify-self-center"
    >
      <div
        className={`relative ${roundedCornerClass} ${elementDesign.readOnlyElementClasses()}`}
        style={inputWrapperStyle}
      >
        {
          data.leadingText &&
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span style={inputStyle} className="text-gray-500 sm:text-sm">
              {elementDesign.translateTerm(data.leadingText, 'leadingText')}
            </span>
          </div>
        }
        <input
          type="text"
          onChange={onChange}
          value={row.value}
          style={inputStyle}
          className={`${leadingTextClass} ${trailingTextClass} element ${roundedCornerClass} block w-full ${classPrefix} ${textAlignClass} ${boldClass} ${italicClass} ${underlineClass}`}
          disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
        />
        {
          data.trailingText &&
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span style={inputStyle} className="text-gray-500 sm:text-sm">
              {suffixWithTypeValidationAndTranslation()}              
            </span>
          </div>
        }
      </div>
    </div>
  )
}
