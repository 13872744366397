import { useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">

      <Property.Group
        title='Information'>

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data.property}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Value"}
          name={"value"}
          value={data?.value}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

      </Property.Group>

      <Property.Group
        title='Text Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data.text.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'text.size'}
          type={'number'}
          value={data.text?.size}
        />

        <Property.Select
          onChange={handleOnChange}
          label={'Header type'}
          name={'text.level'}
          value={data?.text?.level}
          options={[
            { name: "H1", value: '1' },
            { name: "H2", value: '2' },
            { name: "H3", value: '3' },
          ]}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />

      </Property.Group>

    </div>
  )
}
