import { useContext, useState } from 'react';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripDots, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { GlobalDndContext } from '../../contexts';

export const DragHandle = ({
  children,
  isHandleVisible,
  dragHandleProps,
  forSortableType,
  tooltip,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { onDragHandleClick } = useContext(GlobalDndContext) || {}

  const handleDragHandleClick = (e) => {
    const draggableId = dragHandleProps['data-rbd-drag-handle-draggable-id']

    if (draggableId) {
      onDragHandleClick(draggableId)
    }
  }

  const handleProps = isMouseOver && dragHandleProps
    ? { ...dragHandleProps }
    : {}

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseLeave = () => {
    setIsMouseOver(false)
  }

  const getPositioningClasses = () => {
    switch (forSortableType) {
      case "column":
        return "absolute top-0 right-1"
      case "row":
        return "absolute -left-12 h-full"
      case "container":
        return "absolute -right-16 top-8"
      default:
        return ""
    }
  }

  const getWrapperClassName = () => {
    switch (forSortableType) {
      case "element":
        return "flex flex-row"
      default:
        return "relative"
    }
  }

  return (
    <div
      className={getWrapperClassName()}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {
        isHandleVisible && isMouseOver &&
        <div
          className={`z-50 ${getPositioningClasses()}`}
        >
          {
            forSortableType === "container" &&
            <div
              className='bg-white rounded border border-gray-300 pl-2 pr-2 pt-1 pb-1'
              style={{ cursor: 'grab' }}
              onClick={handleDragHandleClick}
              title={tooltip}
              {...handleProps}
            >
              <FontAwesomeIcon size={'sm'} icon={faArrowsAltV} />
            </div>
          }

          {
            forSortableType === "row" &&
            <div className='h-full'>
              <div className='m-auto mr-12'>
                <div
                  className='bg-white rounded border border-gray-300 pl-1 pr-1'
                  style={{ cursor: 'grab' }}
                  onClick={handleDragHandleClick}
                  title={tooltip}
                  {...handleProps}
                >
                  <FontAwesomeIcon size={'md'} icon={faGripDotsVertical} />
                </div>
              </div>
            </div>
          }

          {
            forSortableType === "column" &&
            <div
              className='bg-white rounded border border-gray-300 pl-1 pr-1 pt-0 pb-0'
              style={{ cursor: 'grab' }}
              onClick={handleDragHandleClick}
              title={tooltip}
              {...handleProps}
            >
              <FontAwesomeIcon size={'md'} icon={faGripDots} />
            </div>
          }

          {
            forSortableType === "element" &&
            <div className='h-full flex'>
              <div
                className='bg-white rounded pl-1 pr-1 mr-1 ml-1'
                style={{ cursor: 'grab', alignSelf: "center" }}
                onClick={handleDragHandleClick}
                title={tooltip}
                {...handleProps}
              >
                <FontAwesomeIcon size={'md'} icon={faGripDotsVertical} />
              </div>
            </div>
          }

        </div>
      }
      {children}
    </div>
  )
}