import { useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">
      <Property.Group title="Information">
        <Property.Text
          onChange={propertyToLabel.onChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={"Label"}
          name={"label"}
          value={data?.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Value"}
          name={"value"}
          value={data?.value}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

      </Property.Group>

      <Property.Group
        title='Label Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'text.size'}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />

      </Property.Group>

      <Property.Group
        title='Placement'>

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data.text?.alignment}
          options={[
            { name: "Left", value: 'left' },
            { name: "Center", value: 'center' },
            { name: "Right", value: 'right' }
          ]}
        />

      </Property.Group>
    </div>
  )
}
