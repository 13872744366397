import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import axios from 'axios'

import './styles.scss'

export default function Exclamation({...props}) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )
}
