import { useState, useContext } from 'react'
import { FormContext } from '../../contexts'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle';
import InlineEditing from '../../components/InlineEditing';

export default function Switch({ data, ...props }) {
  const [value, setValue] = useState(data.value);
  const [valueHasChanged, setValueHasChanged] = useState(false);
  const formContext = useContext(FormContext);
  const classPrefix = "switch"
  const multiStep = useMultiStep(data);
  const elementStyle = useElementStyle(data);
  const elementDesign = useElementDesign(data.id, data)

  const leadingText = elementDesign.translateTerm(data?.leadingText, 'leadingText')
  const trailingText = elementDesign.translateTerm(data?.trailingText, 'trailingText')

  const backgroundColorCheckboxStyle = data?.backgroundColor ? data.backgroundColor : 'green'
  
  let justifyClass = 'justify-start';

  switch(data?.text?.alignment)
  {
    case 'center':
      justifyClass = 'justify-center';
      break;
    case 'right':
      justifyClass = 'justify-end';
      break;
    default:
    case 'left':
      justifyClass = 'justify-start';
      break;
  }

  const labelStyle = {wordBreak: "break-word", ...elementStyle.textStyle(data?.text)}
  const labelClass = elementStyle.prefixClassName(classPrefix)

  let currentValue;
  let onChange;

  if (formContext) {
    const formValue = formContext?.inputValues[data.id]?.value
    if (formValue) {
      currentValue = formValue === 'true' ? true : false;
    }
    else {
      currentValue = data.defaultCheckedState ? true : false;
    }

    onChange = () => {
      if (currentValue) {
        formContext.updateValue(data.id, 'false')
      }
      else {
        formContext.updateValue(data.id, 'true')
      }
    }
  }
  else {
    if (valueHasChanged) {
      currentValue = value
    }
    else {
      currentValue = data.defaultCheckedState === true ? true : false;
    }

    onChange = () => {
      setValue(!value);
      setValueHasChanged(true)
    }
  }


  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={`flex items-center ${justifyClass}`}
      >
        {
          leadingText &&
          <span className="mr-3" id="annual-billing-label">
            <span className="text-sm font-medium text-gray-900">
              {leadingText}
            </span>
          </span>
        }
        <button
          onClick={onChange}
          disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
          type="button"
          style={currentValue ? { backgroundColor: backgroundColorCheckboxStyle } : { backgroundColor: '#878686' }}
          className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
        >
          <span className="sr-only">Use setting</span>
          <span aria-hidden="true" className={`translate-x-${currentValue ? '5' : '0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}></span>
        </button>
        <InlineEditing
          initData={data}
          style={labelStyle}
          classes={`ml-3 text-sm text-gray-700 sm:mt-px ${labelClass}`}
          name='label'
          id={`label-${data.id}`}
        >
          <label style={labelStyle} className={`ml-3 text-sm text-gray-700 sm:mt-px ${labelClass}`}>
            {elementDesign.translateTerm(data.label)}
            {!trailingText && elementDesign.translateHelpText(data)}
          </label>
        </InlineEditing>
        {
          trailingText &&
          <span className="ml-3" id="annual-billing-label">
            <span className="text-sm font-medium text-gray-900">
              {trailingText}
              {elementDesign.translateHelpText(data)}
            </span>
          </span>
        }
      </div>
    </InlineActionbarElementWrapper>
  )
}
