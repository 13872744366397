import { useContext, useState } from "react";
import { FormContext, DialogModes } from "../../contexts";
import { useElementDesign } from "../useElementDesign";
import { InlineActionbarElementWrapper } from "../../components/InlineActionbarElementWrapper";
import { useElementStyle } from "../useElementStyle";
import { useSelector } from "react-redux";
import { useMultiStep } from "../../hooks/useMultiStep";
import InlineEditing from "../../components/InlineEditing";

export default function RadioButtonGroup({ data }) {
  const { textStyle, textClassName } = useElementStyle(data);
  const currentDialogDefinition = useSelector(state => state.dialogDefinitions.current)
  const { isPreviewTemplateMode } = useContext(DialogModes)
  const classPrefix = "radio-button-group";
  const elementDesign = useElementDesign(data.id, data);
  const multiStep = useMultiStep(data)
  const [value, setValue] = useState(data.value);

  let rowId
  
  const name = `${classPrefix}-${rowId || ''}`;
  const labelStyle = textStyle(data?.text);
  const labelClass = textClassName(data?.text);

  let currentValue;
  let onChange;
  let radioButtons = document.querySelectorAll(`input[name=${name}]`);
  for (const radioButton of radioButtons) {
    if (radioButton.checked) {
      currentValue = radioButton.value;
      break;
    }
  }

  const formContext = useContext(FormContext);
  if (formContext) {
    currentValue = formContext?.inputValues[data.id]?.value;
  }

  onChange = (e) => {
    currentValue = value;
    if (formContext) {
      formContext.updateValue(data.id, e.target.value, rowId);
    } else {
      setValue(!value);
    }
  };
  
  const isReadOnlyDependency = elementDesign.isReadOnlyDependency(data?.dependencies?.value, data?.dependencies?.type);
  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div className="flex items-center">
      <input
          type="radio"
          value={data?.value}
          name={name}
          className="h-4 w-4 cursor-pointer border-gray-300 mr-2"
          checked={currentValue === data?.value}
          onClick={onChange}
          disabled={elementDesign.isReadOnly() || multiStep.isReadOnly || isReadOnlyDependency}
      />
      <InlineEditing
        initData={data}
        style={labelStyle}
        classes={`element flex-1 ${labelClass}`}
        name='label'
        id={`label-${data.id}`}
      >
        <label
          htmlFor={name}
          className={`element flex-1 ${labelClass}`}
          style={labelStyle}
        >
          {elementDesign.translateTerm(data?.label)}
          {elementDesign.translateHelpText(data)}
        </label>
      </InlineEditing>
      </div>
    </InlineActionbarElementWrapper>
  );
}
