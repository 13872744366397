export const config = {
  // apiBaseUrl: 'https://localhost:5001',
  //apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  //apiBaseUrl: 'https://api.dialog.stage.metaforcelabs.com',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  workflowApiUrl:process.env.REACT_APP_WORKFLOW_BASE_URL,
  tokenStorageKey: '@MetaForce:userToken',
  identityBaseUrl: process.env.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: process.env.REACT_APP_CENTERPOINT_BASE_URL,
  centerpointUiBaseUrl: process.env.REACT_APP_CENTERPOINT_UI_BASE_URL,
  productLicense: '611b8ac465064876fb35e1cf',
  appTitle: process.env.REACT_APP_APP_TITLE || 'Metaforce - Studio',
  favicon: process.env.REACT_APP_APP_FAVICON || '/favicon.ico'

}

// export const oidcConfig = {
//   client_id: 'centerpoint_web',
//   redirect_uri: 'http://localhost:3000/authentication/callback',
//   response_type: 'code',
//   post_logout_redirect_uri: 'http://localhost:3000',
//   scope: 'openid profile email api.internal offline_access',
//   authority: 'https://identity.stage.metaforcelabs.com',
//   silent_redirect_uri: 'http://localhost:3000/authentication/silent_callback',
//   automaticSilentRenew: true,
//   loadUserInfo: true,
// };

export const oidcConfig = {
    client_id: 'dialog_web',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: 'code',
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid profile email api.internal offline_access',
    authority: process.env.REACT_APP_AUTHORITY,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true,
  };

//export const oidcConfig = {
  //   client_id: 'centerpoint_web',
  //   redirect_uri: 'https://dialog.stage.metaforcelabs.com/authentication/callback',
  //   response_type: 'code',
  //   post_logout_redirect_uri: 'https://dialog.stage.metaforcelabs.com/',
  //   scope: 'openid profile email api.internal offline_access',
  //   authority: 'https://identity.stage.metaforcelabs.com',
  //   silent_redirect_uri: 'https://dialog.stage.metaforcelabs.com/authentication/silent_callback',
  //   automaticSilentRenew: true,
  //   loadUserInfo: true,
  // };