import { useEffect, useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import OptionsPopup from '../optionsPopup';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { dependenciesOptions } from '../../utils/constants';
import { getAllCheckboxesOptions } from '../../utils/elements';
import { getAllApiDefinitions } from '../../api/apiDefinition';
export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const [openPopup, setOpenPopup] = useState(false)
  const [apiDefinitionOptions, setApiDefinitionOptions] = useState([]);

  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  useEffect(() => {
    loadApiDefinitionOptions();
  }, [])

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  const loadApiDefinitionOptions = async () => {
    // TODO - instead of loading this for every single Dropdown (and any other component it's loaded in), load it
    // further up the component tree once, set it in either a context or a reducer, and reference it
    var apiDefinitions = await getAllApiDefinitions() 
    var options = [{value: "", name: "None"}]

    apiDefinitions.forEach(apiDefinition => {
      options.push({
        value: apiDefinition.id,
        name: apiDefinition.name
      })
    })

    setApiDefinitionOptions(options)
  }

  let checkboxOptions = getAllCheckboxesOptions(dialogDefinition.containers);

  return (

    <div className="absolute inset-0">
      <Property.Group
        title='Information'>

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'label'}
          value={data.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Placeholder'}
          name={'placeholder'}
          value={data?.placeholder}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.ButtonWithLabel
          label="Items"
          onClick={() => setOpenPopup(true)}
          text='Edit items'
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Required field"}
          name={"requiredField"}
          value={data?.requiredField}
        />
        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />
      </Property.Group>

      <Property.Group
        title='Label Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'labelStyle.color'}
          initColor={data.labelStyle.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'labelStyle.size'}
          type={'number'}
          value={data.labelStyle?.size}
        />

      </Property.Group>

      <Property.Group title="Element Style">
        
        <Property.Checkbox
          onChange={handleOnChange}
          label={"Label top"}
          name={"labelTop"}
          value={data?.labelTop}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Hide label"}
          name={"hideLabel"}
          value={data?.hideLabel}
        />
        
        <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

      </Property.Group>

      <Property.Group title='Dependencies'>

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Type'}
          name={'dependencies.type'}
          value={data?.dependencies?.type}
          options={dependenciesOptions}
        />

        { data?.dependencies?.type === 1 &&
          <Property.Select
            onChange={handleOnChange}
            label={'Checkbox list'}
            name={'dependencies.value'}
            value={data.dependencies?.value}
            options={checkboxOptions}
          />
        }

      </Property.Group>

      <Property.Group title='Api Retrieve'>

      <Property.Select
          onChange={handleOnChange}
          label={"Api Retrieve"}
          name={"apiRetrieveId"}
          value={data?.apiRetrieveId }
          options={apiDefinitionOptions}
        />

      </Property.Group>

      <Property.Group title='Text Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data.text.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'text.size'}
          type={'number'}
          value={data.text?.size}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />

      </Property.Group>


      <Property.Group
        title='Placement'>

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data.text?.alignment}
          options={[
            { name: "Left", value: 'left' },
            { name: "Center", value: 'center' },
            { name: "Right", value: 'right' }
          ]}
        />

      </Property.Group>

      <OptionsPopup data={data} onChange={handleOnChange} open={openPopup} setOpen={setOpenPopup} />
    </div>
  )
}
