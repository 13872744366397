/**
 * @param {*} Group 
 */
export const Group = ({ title, children, divider = false }) => {

  return (
    <>
    <div className={`mb-8 border-b border-gray-200 pb-7`}>
      <h3 className="px-4 sm:px-6 font-medium text-gray-900">{title}</h3>
        <div className={`${divider && "divide-y divide-gray-200 space-y-6"}`}>
          {children}
        </div>
    </div>
    </>
  );
}