import { useState, useEffect, useContext } from 'react';
import {
  getDialogDefinitions,
} from '../../../api/dialogDefinition';
import './styles.scss';
import { useToastAction } from '../../../hooks/useToastAction';
import { SearchIcon } from '@heroicons/react/outline';
import { OidcRoutesContext } from '../../../contexts';
import { useNavigate } from 'react-router-dom';
import TableBody from './tableBody';
import { Table, useTableActions } from 'metaforce-core';


export default function ListSmartForms() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [allDialogDefinitions, setAllDialogDefinitions] = useState([])
  const [dialogDefinitionsToShow, setDialogDefinitionsToShow] = useState([])
  const duplicateDefinitionAction = useToastAction();
  const activateDefinitionAction = useToastAction(true);
  const deactivateDefinitionAction = useToastAction();
  const navigate = useNavigate()
  const [onlyCurrentUserDialogs, setOnlyCurrentUserDialog] = useState(navigate?.location?.state?.onlyUser)
  const tableActions = useTableActions(dialogDefinitionsToShow, 10)
  const loadAction = useToastAction();
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const currentUserId = oidcRoutesContext.customerInfo?.customerId
  const dummyLoadData = [{}, {}, {}, {}, {}];
  // const [folders, setFolders] = useState([])

  const filterOnlyCurrentUser = () => {
    const dataTableToFilter = tableActions?.pageData;

    return onlyCurrentUserDialogs ?
      dataTableToFilter.filter(dataTable => dataTable.createdBy === currentUserId || dataTable.updatedBy === currentUserId) : dataTableToFilter
  }

  useEffect(() => {
    load()
  }, [])

  async function load() {
    loadAction.execute(async () => {
      const result = await getDialogDefinitions();
      result.sort(function (a, b) {
        return new Date(b.updatedDate) - new Date(a.updatedDate);
      });
      setAllDialogDefinitions(result);
      setDialogDefinitionsToShow(result);
      setIsLoaded(true);
    }, "Failed to load dialogs")
  }

  const handleSearch = (e) => {
    const searchBy = e.target.value

    if (searchBy && searchBy !== '') {
      setDialogDefinitionsToShow([...allDialogDefinitions.filter(m => m.name.toLowerCase().includes(searchBy.toLowerCase()))])
    }
    else {
      setDialogDefinitionsToShow([...allDialogDefinitions])
    }
  }

  const SmartFormsTable = () => {
    return (
      <div className='w-full'>
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
            <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        tableActions.orderBy("name")
                      }
                    >
                      Name {tableActions.getSortIcon("name")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => tableActions.orderBy("status")}
                    >
                      Status {tableActions.getSortIcon("status")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        tableActions.orderBy("editState")
                      }
                    >
                      Publish State {tableActions.getSortIcon("editState")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        tableActions.orderBy("createdDate")
                      }
                    >
                      Created {tableActions.getSortIcon("createdDate")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        tableActions.orderBy("updatedDate")
                      }
                    >
                      Updated {tableActions.getSortIcon("updatedDate")}
                    </th>
                  </tr>
                </thead>
                <TableBody
                  data={isLoaded ? filterOnlyCurrentUser() : dummyLoadData}
                  definitionAction={{
                    duplicate: duplicateDefinitionAction,
                    deactivate: deactivateDefinitionAction,
                    activate: activateDefinitionAction,
                  }}
                />
              </table>
              <Table.Pagination tableActions={tableActions} />
            </div>
          </div>
        </div>
      </div>
    )
  }



  return (
    <>
      <div className="relative mt-10 pb-5 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Embedded Internal</h1>
        <div className='flex justify-end mt-5 pb-5'>
          <div className="w-72">
            <label htmlFor="desktop-search-candidate" className="sr-only">
              Search
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="desktop-search-candidate"
                  id="desktop-search-candidate"
                  className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder="Search..."
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      
      <SmartFormsTable  />
    </>
  );
}
