import { useContext } from 'react'
import { DesignActionbarContext } from "../../contexts"
import { PlusButton } from '../../components/PlusButton';

export const AddActions = ({ containerId, ...props }) => {
  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    handleAddContainer,
  } = designActionbarContext

  const onContainerClick = () => {
    handleAddContainer(containerId)
  }

  return (
    <div className='flex items-center my-2 mr-6'>
      <div className='flex-grow bg-black h-0.5 mr-2' />
      <div className='flex-grow-0 text-center'>
        <PlusButton
          onClick={onContainerClick}
        />
      </div>
      <div className='flex-grow bg-black h-0.5 ml-2' />
    </div >
  )
}