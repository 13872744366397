import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash';
import { editStates } from '../utils/constants';

const MAX_HISTORY_LENGTH = 150

export const dialogDefinitionSlice = createSlice({
  name: 'dialogDefinitions',
  initialState: {
    current: null,
    history: []
  },
  reducers: {
    set: (state, action) => {
      const { dialogDefinition, isInitialLoad, skipHistory, additionalPayload } = action.payload;
      if (skipHistory === true) {
        state.current = dialogDefinition
      }
      else {
        const oldCurrent = _.cloneDeep(state.current)
        state.current = dialogDefinition

        if (isInitialLoad) {
          state.history = []
        }

        if (oldCurrent && !isInitialLoad) {
          state.history.push(additionalPayload?.previous ? additionalPayload.previous : oldCurrent)
        }

        if (state.history.length > MAX_HISTORY_LENGTH) {
          state.history.shift()
        }
      }
    },
    undo: (state) => {
      if (state.history.length > 0) {
        state.current = state.history.pop()
      }
    },
    setActiveStatus: (state, action) => {
      state.current.status = action.payload
    },
    publish: (state) => {
      state.current.editState = editStates.published
    },
    setEditState: (state, action) => {
      state.current.editState = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  set: setDialogDefinition,
  undo: undoDialogDefinition,
  setActiveStatus,
  publish,
  setEditState,
} = dialogDefinitionSlice.actions

export default dialogDefinitionSlice.reducer