import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';

export default function Divider({ data, ...props }) {
    const classPrefix = "divider"
    const elementDesign = useElementDesign(data.id, data);
    const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : '1px';
    const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'black';

    const style = {
        borderWidth: borderWidthStyle,
        borderColor: borderColorStyle,
    }

    return (
        <InlineActionbarElementWrapper designElement={elementDesign}>
            <div
                style={style}
            />
        </InlineActionbarElementWrapper>
    )
}