import React, { useState } from 'react'
import * as _ from 'lodash'
import { FormContext } from '../../contexts'

import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'

export default function Disclaimer({ data, ...props }) {
  const classPrefix = "btn"
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'white'
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  let colorClass = data?.text?.color ? `${classPrefix}--color-${data.text.color}` : ''
  let colorStyle = 'inherit'

  if (data?.text?.color && data?.text?.color.startsWith('#')) {
    colorStyle = data?.text?.color
    colorClass = ''
  }

  const textAlignClass = data?.text?.alignment ? `${classPrefix}--textalign-${data.text.alignment}` : ''
  const boldClass = data?.text?.bold ? `${classPrefix}--bold` : ''
  const italicClass = data?.text?.italic ? `${classPrefix}--italic` : ''
  const underlineClass = data?.text?.underline ? `${classPrefix}--underline` : ''

  const fontSizeStyle = data?.text?.size ? data.text.size : 'inherit'
  const fontFamilyStyle = data?.text?.fontFamily ? `${data.text.fontFamily}, Arial, sans-serif` : `Arial, sans-serif`

  const style = {
    fontFamily: fontFamilyStyle,
    fontSize: fontSizeStyle,
    color: colorStyle
  }

  const [value, setValue] = useState(data.value);
  const formContext = React.useContext(FormContext);

  let currentValue;
  let onChange;
  if (formContext) {
    currentValue = formContext?.inputValues[data.id]?.value === 'true' ? true : false;
    onChange = () => {
      if (currentValue) {
        formContext.updateValue(data.id, 'false')
      }
      else {
        formContext.updateValue(data.id, 'true')
      }
    }
  }
  else {
    currentValue = value;
    onChange = () => {
      setValue(!value);
    }
  }

  const elementDesign = useElementDesign(data.id);

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div className={`relative flex items-start`}>
        <div className="flex items-center h-5 self-center">
          <input
            id="disclaimer_checkbox"
            name="disclaimer_checkbox"
            type="checkbox"
            checked={currentValue}
            onChange={onChange}
            className="focus:ring-green-500 h-6 w-6 text-green-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-6 text-sm">
          {
            data.label &&
            <label
              htmlFor="disclaimer_checkbox"
              className="font-medium text-gray-700 block"
              style={style}
            >
              {elementDesign.translateTerm(data.label)}
              {elementDesign.translateHelpText(data)}
            </label>
          }
          <label
            htmlFor="disclaimer_checkbox"
            className="font-medium text-gray-700"
            style={style}
          >
            {data.value}
          </label>
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}
