import { useContext, useState } from "react";
import ColorPicker from "../../components/ColorPicker";
import * as Property from "../../components/Properties";
import { ElementPropertiesContext } from "../../contexts";
import { setProperty } from "../../utils/property";
import OptionsPopup from "../optionsPopup";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { tableValueTypes } from "../../utils/constants";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState(_.cloneDeep(initData));
  const [openPopup, setOpenPopup] = useState(false)

  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type);

    setProperty(data, name, value, type);
    setSetData((data) => ({
      ...data,
    }));
  };

  return (
    <div className="absolute inset-0">

      <Property.Group title="Information">
        <Property.Text
          onChange={handleOnChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"First column"}
          name={"displayLabel"}
          value={data?.displayLabel}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Second column"}
          name={"valueLabel"}
          value={data?.valueLabel}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Lock rows"}
          name={"lockRows"}
          value={data?.lockRows}
        />

        <Property.ButtonWithLabel
          label="Items"
          onClick={() => setOpenPopup(true)}
          text='Edit items'
        />

        <Property.Select
          onChange={(e) => handleOnChange(e, "number")}
          label={"Values type"}
          name={"tableValueType"}
          value={data?.tableValueType}
          options={[
            { name: "Number", value: 0 },
            { name: "Percent", value: 1 },
          ]}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Required field"}
          name={"requiredField"}
          value={data?.requiredField}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />
      </Property.Group>

      <Property.Group title="Element Style">

        <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Suffix'}
          name={'trailingText'}
          value={data?.trailingText}
          disabled={data?.tableValueType !== tableValueTypes.number}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Prefix'}
          name={'leadingText'}
          value={data?.leadingText}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

      </Property.Group>

      <Property.Group
        title='Header Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'labelStyle.color'}
          initColor={data?.labelStyle?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'labelStyle.size'}
          type={'number'}
          value={data?.labelStyle?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"labelStyle.bold"}
          value={data?.labelStyle?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"labelStyle.italic"}
          value={data?.labelStyle?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"labelStyle.underline"}
          value={data?.labelStyle?.underline}
        />

      </Property.Group>

      <Property.Group
        title='Items Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'text.size'}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />

      </Property.Group>

      <OptionsPopup data={data} onChange={handleOnChange} open={openPopup} setOpen={setOpenPopup} />
    </div>
  );
}
