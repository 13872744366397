import { InlineActionbar } from "../InlineActionbar";
import { useRef } from "react";
import useClickOutside from "../../hooks/useClickOutside";

export const InlineActionbarElementWrapper = ({ designElement, children, openElementProperties = false}) => {
  const ref = useRef();

  useClickOutside(ref, () => {
    designElement.removeActionBarSelectedElement();
  });

  const close = () => {
    designElement.removeActionBarSelectedElement();
  }

  const handleOpen = (e) => {
    designElement.onClick(e, openElementProperties);
  }

  return (
    <div ref={ref} className={`${designElement.elementsClasses()}`} onClick={(e) => handleOpen(e)}>
      <InlineActionbar type={'element-actionbar'} close={close} isOpen={designElement.isActionBarSelectedElement()} />
      <div>
        {children}
      </div>
    </div>
  );
}