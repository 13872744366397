import { useState } from "react";
import { setProperty } from "../../utils/property";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">
      <Property.Group title="Information">
        <Property.Text
          onChange={propertyToLabel.onChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.TextArea
          onChange={labelUpdate.onChange}
          label={"Text"}
          name={"label"}
          value={data?.label}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Url"}
          name={"value"}
          value={data?.value}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Checkbox 
          onChange={handleOnChange}
          label={"Show hypherlink as button"}
          name={"isButton"}
          value={data?.isButton}
        />
      </Property.Group>

      <Property.Group title="Text Style">
        <Property.Color
          onChange={handleOnChange}
          label={"Color"}
          name={"text.color"}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />
      </Property.Group>

      <div className={`${!data?.isButton ? "hidden" : ""}`}>  
        <Property.Group title="Button Style">
          <Property.Color
            onChange={handleOnChange}
            label={'Background color'}
            name={'backgroundColor'}
            initColor={data?.backgroundColor}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Width'}
            name={'width'}
            type={'number'}
            value={data?.width}
          />

          <Property.Checkbox
            onChange={handleOnChange}
            label={"RoundedCorners"}
            name={"roundedCorners"}
            value={data?.roundedCorners}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Border color'}
            name={'borderColor'}
            initColor={data.borderColor}
          />

          <Property.BorderStyleAndWidth
            onChange={handleOnChange}
            label={'Border'}
            name={'borderWidth'}
            value={data.borderWidth}
          />
        </Property.Group>
      </div>

      <Property.Group title="Placement">

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data?.text?.alignment}
          options={[
            { name: "Left", value: 'left' },
            { name: "Center", value: 'center' },
            { name: "Right", value: 'right' }
          ]}
        />

      </Property.Group>

    </div>
  );
}
