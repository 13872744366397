import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges, 'value')
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">
      <Property.Group
        title='Information'>

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Label"}
          name={"valueLabel"}
          value={data.valueLabel}
        />

        <Property.Text
          onChange={labelUpdate.onChange}        
          label={'Description'}
          name={'label'}
          value={data.label}
        /> 


        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />
        
        <Property.Color
          onChange={handleOnChange}
          label={'Background'}
          name={'backgroundColor'}
          initColor={data.backgroundColor}
        />

        <Property.Toggle
          onChange={handleOnChange}
          label={'Default state'}
          name="defaultCheckedState"
          onText="On"
          offText="Off"
          enabled={data.defaultCheckedState}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
        />

      </Property.Group>

      <Property.Group
        title='Label Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'text.size'}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"text.bold"}
          value={data?.text?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"text.italic"}
          value={data?.text?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"text.underline"}
          value={data?.text?.underline}
        />

      </Property.Group>

      <Property.Group
        title='Description Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'labelStyle.color'}
          initColor={data?.labelStyle?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'labelStyle.size'}
          type={'number'}
          value={data?.labelStyle?.size || null}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Bold"}
          name={"labelStyle.bold"}
          value={data?.labelStyle?.bold}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Italic"}
          name={"labelStyle.italic"}
          value={data?.labelStyle?.italic}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Underline"}
          name={"labelStyle.underline"}
          value={data?.labelStyle?.underline}
        />

      </Property.Group>
    </div>
  )
}
