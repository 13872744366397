import React, { useEffect, useState } from 'react'
import { ContainerContext } from "../contexts";
import { Locked } from '../structures/Container/locked';
import Properties from '../structures/Container/properties';

export const useContainerDesign = (containerId, data, isLocked) => {
  const containerContext = React.useContext(ContainerContext);
  const isContextActive = containerContext// && containerContext.enabled
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (hasLoaded && isContextActive) {
      if (isLocked) {
        containerContext.showPropertiesPanel(containerId, data, isLocked, (<Locked containerId={containerId} initData={data} actions={containerContext.actions} />));
      }
      else {
        containerContext.showPropertiesPanel(containerId, data, isLocked, (<Properties initData={data} actions={containerContext.actions} />));
      }
    }
    setHasLoaded(true)
  }, [isLocked])

  return {
    onClick: (evt) => {
      if (isContextActive) {
        try {
          // prevent default event behavior if there is any
          evt.preventDefault()
        }
        catch (err) {
          // do nothing
        }

        if (isLocked) {
          containerContext.showPropertiesPanel(containerId, data, isLocked, (<Locked containerId={containerId} initData={data} actions={containerContext.actions} />));
        }
        else {
          containerContext.showPropertiesPanel(containerId, data, isLocked, (<Properties initData={data} actions={containerContext.actions} />));
        }
      }
    },
    onHover: () => {
      if (isContextActive) {
        containerContext.onHover(containerId, data, isLocked);
      }
    },
    containersClasses: (backgroundColor) => {
      let classes = ''

      if (containerContext?.showGrid) {
        classes = 'grid-container '
      }

      const isNew = containerContext?.newContainerId === containerId;

      if (isContextActive || isNew) {
        const isSelected = containerContext.currentContainerId === containerId;

        classes += 'design-container';
        classes += !backgroundColor ? ' background' : ''
        classes += isSelected ? ' active' : ''
        classes += isNew ? ' new' : ''
      }

      return classes
    },
    hasContext: () => {
      return isContextActive;
    },
    isSelected: () => {
      return containerContext?.currentContainerId === containerId
    }
  }
}