import React, { useState, useContext, useRef, useEffect } from 'react'
import * as _ from 'lodash'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementDesign } from '../useElementDesign'
import { useSelector } from 'react-redux';
import { DialogModes, ElementContext } from '../../contexts';
import { setProperty } from '../../utils/property';
import "./styles.scss";
import RichTextWrapper from '../../components/RichText';
import { OpenElementProperties } from '../../utils/elements'

const defaultRichTextData = {
  type: 22,
  property: 'Logo',
  value: 'no value',
  placeholder: 'Metaforce logo',
  layout: {
    order: 5,
  },
  styles: {
    borderWidth: 0
  }
}

export default function RichText({ data, ...props }) {
  data = data || defaultRichTextData

  const [clonedData, setSetData] = useState(_.cloneDeep(data));
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const dialogDefinitionRef = useRef()
  const elementDesign = useElementDesign(data.id, data)
  const elementContext = useContext(ElementContext);
  const { isEditMode } = useContext(DialogModes);

  useEffect(() => {
    // a bit of a hack to get around closure issues. If dialogDefinitionis used in 
    // handleOnChangeContent it won't always be the most up to date dialog definition
    // which can cause save issues that will undo recent work
    dialogDefinitionRef.current = dialogDefinition
  }, [dialogDefinition])

  const handleOnChangeContent = (value) => {
    const name = "richTextValues.contentInHtml"
    const type = "text"
    const customEventObject = {
      target: {
        name: name,
        value
      }
    };

    elementContext.actions.handleChange(dialogDefinitionRef.current, customEventObject, clonedData.id, type)

    setProperty(clonedData, name, value, type)
    setSetData(clonedData => ({
      ...clonedData
    }));
  };

  const contentInHtmlWithTranslation = () => {
    const translatedData = elementDesign.translateTerm(clonedData?.richTextValues, "richTextValues")
    return translatedData?.contentInHtml;
  }

  return (
    !isEditMode ?
      (
        <div dangerouslySetInnerHTML={{ __html: contentInHtmlWithTranslation() }} className={`ql-editor editor-id-${data?.id}`} style={{ padding: '0', fontSize: '13px', fontFamily: 'sans-serif' }} />
      )
      :
      (
        <InlineActionbarElementWrapper designElement={elementDesign}>
          <RichTextWrapper
            data={data}
            handleOnChangeContent={handleOnChangeContent}
            elementsClasses={elementDesign.elementsClasses()}
            onInputClicked={(e) => OpenElementProperties(e, elementDesign.onClick)}
          />
        </InlineActionbarElementWrapper>
      )
  )
}
