import { useContext, useState } from 'react';
import ColorPicker from '../../components/ColorPicker';
import * as Property from "../../components/Properties";
import { ElementPropertiesContext } from '../../contexts';
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">

    </div>
  )
}
