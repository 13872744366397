import React, { useState, useEffect } from 'react'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { FormContext } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import InlineEditing from '../../components/InlineEditing'

const defaultHeaderLineData = {
    type: 3,
    property: 'test',
    value: 'Metaforce',
    layout: {
        order: 2,
    },
    styles: {
        borderWidth: 1
    },
    text: {
        level: '1',
        alignment: 'left',
        fontFamily: 'arial',
        size: 24,
        bold: false,
        italic: false,
        underline: false,
        color: 'black'
    }
}

const elements = {
    '1': 'h1',
    '2': 'h2',
    '3': 'h3',
    '4': 'h4',
    '5': 'h5',
    '6': 'h6',
};

function Heading({ type, children, ...props }) {
    return React.createElement(
        elements[type] || elements.h1,
        props,
        children
    );
}

Heading.defaultProps = {
    type: '1',
};

export default function HeaderLine({ data, ...props }) {
    data = data || defaultHeaderLineData
    const formContext = React.useContext(FormContext);

    const classPrefix = "header"
    const elementStyle = useElementStyle(data);
    const { width, ...borderStyles } = elementStyle.borderAndWidthStyle();
    const { justifyContent, ...textStyles } = elementStyle.textStyle(data?.text);

    const style = {
        ...borderStyles,
        ...textStyles,
    }

    const elementDesign = useElementDesign(data.id, data);
    const [textToShow, setTextToShow] = useState("");
    const textValue = formContext?.inputValues[data.id]?.value || data.value
    const type = elements[data?.text?.level];

    useEffect(() => {
        setTextToShow(elementDesign.translateTerm(textValue))
    }, [textValue, elementDesign])

    useEffect(() => {
        if(formContext?.reusableProperties) {
        let text = elementDesign.translateTerm(textValue)

        Object.keys(formContext?.reusableProperties).forEach(function(key,index) {
            text = text.replace(`{{ ${key} }}`, formContext?.reusableProperties[key] || '')   
        });

        setTextToShow(text)
        }
    }, [formContext?.reusableProperties])


    return (
        <InlineActionbarElementWrapper designElement={elementDesign}>
            <InlineEditing
                initData={data}
                style={style}
                classes={`element ${type} ${classPrefix} ${elementStyle.prefixClassName(classPrefix)}`}
                name='value'
                id={data.id}
                value={textToShow || ""}
                autoSize
            >
                <Heading 
                    type={data?.text?.level}
                    id={data.id}
                    className={`element break-words ${classPrefix} ${elementStyle.prefixClassName(classPrefix)}`}
                    style={style}
                >
                    {textToShow}
                    {elementDesign.translateHelpText(data)}
                </Heading>
            </InlineEditing>
        </InlineActionbarElementWrapper>
    )
}