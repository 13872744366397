import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { createDialogValues, generatePdf, getByDialogDefinitionId } from "../../../api/dialogValues";
import { StringListColumn, Table, TableBody, TableColumn, TableHead, TableHeader, TableRow } from "../../../components/Table";
import { getWebhooksByDialogDefinitionId } from "../../../api/webhook";
import { Button } from "../../../components/Form/Button";
import { WebhookPayload } from "./WebhookPayload";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import { Badge } from "../../../components/Badge";
import { useToastAction } from "../../../hooks/useToastAction";
import { useTableActions } from "metaforce-core";
import { processTypes } from "../../../utils/constants";

export default function ListValuesOnForm() {
  const { dialogKey } = useParams();
  const [dialogValuesList, setDialogValuesList] = useState([]);
  const [webhook, setWebhook] = useState({});
  const [currentDialogValuesId, setCurrentDialogValuesId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const loadAction = useToastAction();
  const [smartformUrl, setSmartformUrl] = useState(null);

  const tableActions = useTableActions(dialogValuesList, 5, "updatedDate", "desc")

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    loadAction.execute(async () => {
      const [dialogValuesResult, webhooksResult] = await Promise.all([
        getByDialogDefinitionId(dialogKey),
        getWebhooksByDialogDefinitionId(dialogKey)
      ]);

      if (webhooksResult?.length > 0) {
        setWebhook(webhooksResult[0]);
      }
      setDialogValuesList(dialogValuesResult);
      setIsLoading(false)
    }, "Failed to load")
  }

  const handleCreateForm = async () => {
    const result = await createDialogValues(dialogKey);
    setSmartformUrl(`https://smartforms.stage.metaforcelabs.com/dialog/${dialogKey}/form/${result.id}`);
    await load();
    // console.log('result', result);
  }

  const handleRowClick = (dialogValuesId) => {
    // setCurrentDialogValuesId(dialogValuesId);
    setSmartformUrl(`https://smartforms.stage.metaforcelabs.com/dialog/${dialogKey}/form/${dialogValuesId}`);
  }

  if (isLoading) {
    return null;
  }

  return (
    <>
    <Button
              // onClick={() => setCurrentDialogValuesId(d.id)}
              onClick={handleCreateForm}
              text="Create form"
            />
      {
        dialogValuesList &&
        <section className="mt-10">
          <Table tableActions={tableActions}>
            <TableHead>
              <TableHeader>Id</TableHeader>
              <TableHeader>
                Created by
              </TableHeader>
              <TableHeader>
                Participating Parties
              </TableHeader>
              <TableHeader sortBy="isComplete" tableSort={tableActions}>
                Status
              </TableHeader>
              <TableHeader sortBy="updatedDate" tableSort={tableActions}>
                Last updated
              </TableHeader>
              <TableHeader>Webhook payload</TableHeader>
            </TableHead>
            <TableBody>
              {
                tableActions?.pageData?.map(d => {
                  return (
                    <TableRow key={d.id}>
                      <TableColumn>
                        <span
                          onClick={() => handleRowClick(d.id)}
                          className="text-black underline hover:text-gray-700 cursor-pointer"
                          >
                          {d.id}
                        </span>
                      </TableColumn>
                      <TableColumn>
                        {d.createdByEmail ? d.createdByEmail : d.createdBy}
                      </TableColumn>
                      <TableColumn>
                        {
                          d.process === processTypes.multiStep ?
                            <StringListColumn
                              records={d.dialogValuesParticipatingParties?.map(p => `${p.email} (${p.name})`)}
                              numberOfCharactersToShow={75}
                              joinCharacter={", "}
                            />
                            : <>{`${d.receiverEmail} (${d.receiverName})`}</>
                        }
                      </TableColumn>
                      <TableColumn>
                        {
                          d.isComplete
                            ? <Badge type="success" text="Complete" />
                            : <Badge type="warn" text="Draft" />
                        }
                      </TableColumn>
                      <TableColumn>{stringToLocaleDateTimeString(d.updatedDate)}</TableColumn>
                      <TableColumn>

                        <Button
                          onClick={() => setCurrentDialogValuesId(d.id)}
                          text="View payload"
                        />
                      </TableColumn>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          <WebhookPayload
            dialogValuesId={currentDialogValuesId}
            webhook={webhook}
            onClose={() => setCurrentDialogValuesId(null)}
          />

          <iframe
            id="pdfFrame"
            className="border mt-10"
            src={smartformUrl}
            title="pdfFrame"
            width="100%"
            height="1000"
          />

        </section>
      }

      {
        !dialogValuesList &&
        <h1>Could not find dialog definition. Or no access.</h1>
      }
    </>
  );
}