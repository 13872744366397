import { useContext, useMemo, useRef, useState } from 'react'
import './styles.scss'
import * as Elements from "../../elements";
import { useColumnDesign } from '../../elements/useColumnDesign';
import { elementTypes } from '../../utils/constants';
import { ContainerLockedContext, DialogModes, ElementContext } from '../../contexts';
import { InlineActionbar } from '../../components/InlineActionbar';
import useClickOutside from '../../hooks/useClickOutside';
import { DndSortable } from '../../components/DragAndDrop/sortable'
import { SortableContext } from "@dnd-kit/sortable"

function ElementFromType({ element, dialogData, process, ...props }) {
  switch (element.type) {
    case elementTypes.textLine:
      return <Elements.TextLine data={element} />
    case elementTypes.hyperLink:
      return <Elements.HyperLink data={element} />
    case elementTypes.textArea:
      return <Elements.TextArea data={element} />
    case elementTypes.inputTextLine:
      return <Elements.InputTextLine data={element} />
    case elementTypes.headerLine:
      return <Elements.HeaderLine data={element} />
    case elementTypes.bitmatp:
      return <Elements.Bitmap data={element} />
    case elementTypes.video:
      return <Elements.Video data={element} />
    case elementTypes.datePicker:
      return <Elements.DatePicker data={element} />
    case elementTypes.button:
      return <Elements.Button data={element} dialogData={dialogData} />
    case elementTypes.switch:
      return <Elements.Switch data={element} />
    case elementTypes.divider:
      return <Elements.Divider data={element} />
    case elementTypes.radioList:
      return <Elements.RadioList data={element} />
    case elementTypes.table:
      return <Elements.Table data={element} />
    case elementTypes.radioListAsGroup:
      return <Elements.RadioListAsGroup data={element} />
    case elementTypes.fileUpload:
      return <Elements.FileUpload data={element} />
    case elementTypes.radioButtons:
      return <Elements.RadioButtons data={element} />
    case elementTypes.disclaimer:
      return <Elements.Disclaimer data={element} />
    case elementTypes.dropDown:
      return <Elements.Dropdown data={element} />
    case elementTypes.flowSelector:
      return <Elements.FlowSelector data={element} />
    case elementTypes.checkbox:
      return <Elements.Checkbox data={element} />
    case elementTypes.cancelButton:
      return <Elements.CancelButton data={element} />
    case elementTypes.signature:
      return <Elements.Signature process={process} data={element} />
    case elementTypes.timePicker:
      return <Elements.TimePicker data={element} />
    case elementTypes.attachment:
      return <Elements.Attachment data={element} />
    case elementTypes.richText:
      return <Elements.RichText data={element} />
    case elementTypes.dynamicTable:
      return <Elements.DynamicTable data={element} />
    case elementTypes.dynamicList:
      return <Elements.DynamicList data={element} />
    case elementTypes.radioButtonGroup:
      return <Elements.RadioButtonGroup data={element} />
    case elementTypes.spacer:
      return <Elements.Spacer data={element} />
    default:
      return <></>
  }
}

export default function Column({ data, dialogData, callbackSelectedComponents, ...props }) {
  const classPrefix = "column"
  const { isEditMode } = useContext(DialogModes)
  const containerLockedContext = useContext(ContainerLockedContext)
  let alignmentClass = "";
  switch (data?.contentAlign) {
    case 'center':
      alignmentClass = "place-content-center";
      break
    case 'right':
      alignmentClass = "justify-end";
      break
    case 'left':
      alignmentClass = "justify-start";
      break
    default:
      alignmentClass = "";
      break
  }
  const ref = useRef();

  const gapClass = data?.space ? `gap-${data.space}` : ''
  const spanClass = data?.span ? `sm:col-span-${data.span} print:col-span-${data.span}` : 'sm:col-auto print:col-auto'
  const roundedClass = data?.roundedCorners ? 'rounded-md' : ''

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderColor ? `${data.borderColor}` : 'inherit'
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'transparent'

  const marginStyle = data?.margin ? data.margin : 'default'
  const paddingStyle = data?.padding ? data.padding : 'default'

  const style = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    backgroundColor: backgroundColorStyle,
    margin: marginStyle,
    padding: paddingStyle
  }
  const elementContext = useContext(ElementContext);
  const columnDesign = useColumnDesign(data.id, data)

  const handleShowProperties = () => {
    columnDesign.onClick()
  }

  const [isModalOpen, setModalOpen] = useState(false);
  useClickOutside(ref, () => setModalOpen(false));

  const close = () => {
    setModalOpen(false);
  }

  const handleColumnClick = () => {
    setModalOpen(columnDesign.isActive)
    columnDesign.onClick();
  }

  const sortableItems = useMemo(() => data.elements || [], [data.elements])

  const getElementFromType = (element) => {
    return <ElementFromType process={elementContext?.process} dialogData={dialogData} element={element} />
  }

  const getWrapperClassFromType = (type) => {
    switch (type) {
      case elementTypes.richText:
        return "min-w-full";
      default:
        return "min-w-0";
    }
  }

  return (
    <>

      <div
        ref={ref}
        className={`grid auto-rows-min ${alignmentClass} ${gapClass} ${spanClass} ${roundedClass} ${columnDesign.columnsClasses()}`}
        style={style} onClick={handleColumnClick}
      >
        <InlineActionbar close={close} isOpen={isModalOpen && columnDesign.isSelected()} type={'column-actionbar'} onShowPropertiesClick={columnDesign.onClick} />
        {
          isEditMode && sortableItems && !containerLockedContext.isLocked
            ? (
              <SortableContext
                id={`column-sortable-context-${data.id}`}
                items={sortableItems.map(i => `element-${i.id}`)}
              >
                {
                  sortableItems.map(element => {
                    return (
                      <DndSortable
                        key={`element-${element.id}`}
                        type="element"
                        id={`element-${element.id}`}
                        dragHandle={true}
                        dragHandleTooltip="Drag element"
                      >
                        <div className={getWrapperClassFromType(element.type)}>
                          {getElementFromType(element)}
                        </div>
                      </DndSortable>
                    )
                  })
                }
              </SortableContext>
            )
            : (
              data.elements && data.elements.map((element, index) => {
                return (
                  <div className={getWrapperClassFromType(element.type)} key={`element-${element.id}`}>
                    {getElementFromType(element)}
                  </div>
                )
              })
            )
        }
      </div>
    </>
  )
}
