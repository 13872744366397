import { useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">

      <Property.Group title="Element Style">

        <Property.Text
          type={"number"}
          onChange={(e) => handleOnChange(e, "number")}
          label={'Height'}
          name={'height'}
          value={data.height}
        />

        <Property.Text
          type={"number"}
          onChange={(e) => handleOnChange(e, "number")}
          label={'Width'}
          name={'width'}
          initColor={data.width}
        />

      </Property.Group>

    </div>
  )
}
