import React from 'react'
import * as _ from 'lodash'

import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useFileSaver } from '../../hooks/useFileSaver';

import { saveAs } from 'file-saver';

export default function Attachment({ data, ...props }) {
  const elementDesign = useElementDesign(data.id, data)

  const downloadBase64File = () => {
    if (!elementDesign.hasContext()) {
      saveAs(data?.value, data?.label);
    }
  }

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div
        className={`${elementDesign.elementsClasses()}`}
        onClick={elementDesign.onClick}
      >
        <div className="relative">
          <h3 className="text-sm font-semibold text-gray-800">
            <a href="#" className="hover:underline focus:outline-none" onClick={() => downloadBase64File()}>
              {elementDesign.translateTerm(data.label)}
            </a>
            {elementDesign.translateHelpText(data)}
          </h3>
          <p className="mt-1 text-sm text-gray-600 line-clamp-2">
            {elementDesign.translateTerm(data.placeholder, 'description')}
          </p>
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}
