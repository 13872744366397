import { useEffect, useState, useContext } from 'react'
import { useElementDesign } from "../useElementDesign"
import './styles.scss'
import Icon from "../../icons"
import { FormContext, DialogModes } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import InlineEditing from '../../components/InlineEditing'

export default function TextLine({ data, ...props }) {
  const formContext = useContext(FormContext);
  const [textToShow, setTextToShow] = useState("");
  const elementDesign = useElementDesign(data.id, data);
  const elementStyle = useElementStyle(data);
  const { isEditMode } = useContext(DialogModes);
  const isLocked = elementDesign.isLocked();

  const classPrefix = "textLine";
  const { ...borderStyle} = elementStyle.borderAndWidthStyle();
  const { color, fontFamily, fontSize, width  } = elementStyle.textStyle(data?.text);
  const roundedCorners = data?.roundedCorners ? 'rounded-md' : '';

  const textLineStyle = {
    fontFamily: fontFamily,
    fontSize: fontSize,
    color: color,
    lineHeight : 1.4,
    width: width,
    ...borderStyle,
  }

  const iconStyle = {
    fontSize: fontSize,
    color: color
  }
  


  const textValue = formContext?.inputValues[data.id]?.value || data.value

  useEffect(() => {
    setTextToShow(elementDesign.translateTerm(textValue))
  }, [data, elementDesign, textValue])

  useEffect(() => {
    if(formContext?.reusableProperties) {
      let text = elementDesign.translateTerm(textValue)

      Object.keys(formContext?.reusableProperties).forEach(function(key,index) {
        text = text.replace(`{{ ${key} }}`, formContext?.reusableProperties[key] || '')   
      });

      setTextToShow(text)
    }
  }, [formContext?.reusableProperties])


  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div className={`${isEditMode && !isLocked ? 'flex items-center justify-center' : ''}`}>
        { isEditMode && !isLocked && <Icon style={iconStyle} type={data?.leadingIcon} /> }
        <InlineEditing
          initData={data}
          style={textLineStyle}
          classes={`element py-2 ${classPrefix} ${elementStyle.prefixClassName(classPrefix)} ${roundedCorners}`}
          name='value'
          value={textToShow}
          id={`label-${data.id}`}
          autoSize
        >
          <p 
            className={`element ${classPrefix} ${elementStyle.prefixClassName(classPrefix)} ${roundedCorners}`}
            style={textLineStyle}
          >
            <Icon style={iconStyle} type={data?.leadingIcon} />
            <span className='whitespace-pre-line break-words'>
              {elementDesign.translateTerm(textToShow, "term")}
              {elementDesign.translateHelpText(data)}
            </span>
          </p>
        </InlineEditing>
      </div>
    </InlineActionbarElementWrapper>
  )
}
