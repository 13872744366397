import { useState, useContext } from 'react'
import { ContainerLockedContext, ElementContext, FormContext } from "../contexts";
import PropertiesInputTextLine from './InputTextLine/properties';
import PropertiesHeaderLine from './HeaderLine/properties';
import PropertiesTextLine from './TextLine/properties';
import PropertiesTable from './Tables/properties';
import PropertiesTextArea from './TextArea/properties';
import PropertiesBitmap from './Bitmap/properties';
import PropertiesDatePicker from './DatePicker/properties';
import PropertiesButton from './Button/properties';
import PropertiesCancelButton from './CancelButton/properties';
import PropertiesCheckbox from './Checkbox/properties';
import PropertiesSwitch from './Switch/properties';
import PropertiesDivider from './Divider/properties';
import PropertiesRadioList from './RadioList/properties';
import PropertiesRadioListAsGroup from './RadioListAsGroup_ToDelete/properties';
import PropertiesFileUpload from './FileUpload/properties';
import PropertiesRadioButtons from './RadioButtons_ToDelete/properties'
import PropertiesDisclaimer from './Disclaimer/properties';
import PropertiesDropdown from './Dropdown/properties';
import PropertiesFlowSelector from './FlowSelector/properties'
import PropertiesSignature from './Signature/properties'
import PropertiesAttachment from './Attachment/properties'
import PropertiesTimePicker from './TimePicker/properties'
import PropertiesVideo from "./Video/properties"
import PropertiesHyperLink from "./HyperLink/properties"
import PropertiesRichText from "./RichText/properties"
import PropertiesRadioButtonGroup from './RadioButtonGroup/properties';
import PropertiesSpacer from './Spacer/properties';
import PropertiesDynamicTable from './DynamicTable/properties';
import PropertiesDynamicList from './DynamicList/properties';
import { elementTypes } from '../utils/constants';
import { Tooltip } from './Tooltip';
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Locked } from '../structures/Element/locked';
import { dependenciesType } from '../utils/constants';
import { isValidInputTextLine } from '../utils/validators';

export const useElementDesign = (elementId, data) => {
  const elementContext = useContext(ElementContext);
  const formContext = useContext(FormContext);
  const containerLockedContext = useContext(ContainerLockedContext);
  const [touched, setTouched] = useState(false)
  const [isValid, setIsValid] = useState(null);
  const isContextActive = elementContext && elementContext.enabled
  const isLocked = containerLockedContext.isLocked

  const getPropertiesOnElement = () => {
    let propertiesOnElement;
    if (isContextActive && data) {
      switch (data.type) {
        case elementTypes.textLine:
          propertiesOnElement = <PropertiesTextLine initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.textArea:
          propertiesOnElement = <PropertiesTextArea initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.inputTextLine:
          propertiesOnElement = <PropertiesInputTextLine initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.headerLine:
          propertiesOnElement = <PropertiesHeaderLine initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.bitmatp:
          propertiesOnElement = <PropertiesBitmap initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.datePicker:
          propertiesOnElement = <PropertiesDatePicker initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.button:
          propertiesOnElement = <PropertiesButton initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.switch:
          propertiesOnElement = <PropertiesSwitch initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.divider:
          propertiesOnElement = <PropertiesDivider initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.radioList:
          propertiesOnElement = <PropertiesRadioList initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.table:
          propertiesOnElement = <PropertiesTable initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.radioListAsGroup:
          propertiesOnElement = <PropertiesRadioListAsGroup initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.fileUpload:
          propertiesOnElement = <PropertiesFileUpload initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.radioButtons:
          propertiesOnElement = <PropertiesRadioButtons initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.disclaimer:
          propertiesOnElement = <PropertiesDisclaimer initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.dropDown:
          propertiesOnElement = <PropertiesDropdown initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.flowSelector:
          propertiesOnElement = <PropertiesFlowSelector initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.checkbox:
          propertiesOnElement = <PropertiesCheckbox initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.cancelButton:
          propertiesOnElement = <PropertiesCancelButton initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.signature:
          propertiesOnElement = <PropertiesSignature initData={data} process={elementContext.process} actions={elementContext.actions} />
          break;
        case elementTypes.attachment:
          propertiesOnElement = <PropertiesAttachment initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.timePicker:
          propertiesOnElement = <PropertiesTimePicker initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.video:
          propertiesOnElement = <PropertiesVideo initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.hyperLink:
          propertiesOnElement = <PropertiesHyperLink initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.richText:
          propertiesOnElement = <PropertiesRichText initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.radioButtonGroup:
          propertiesOnElement = <PropertiesRadioButtonGroup initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.spacer:
          propertiesOnElement = <PropertiesSpacer initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.dynamicTable:
          propertiesOnElement = <PropertiesDynamicTable initData={data} actions={elementContext.actions} />
          break;
        case elementTypes.dynamicList: 
          propertiesOnElement = <PropertiesDynamicList initData={data} actions={elementContext.actions} />
          break;
        default:
          propertiesOnElement = <></>
          break;
      }
    }
    return propertiesOnElement
  }

  return {
    isActive: () => {
      return isContextActive;
    },
    onClick: (evt, openPanel = false) => {
      if (isContextActive) {
        try {
          // prevent default event behavior if there is any
          evt.preventDefault()
        }
        catch (err) {
          // do nothing
        }

        if (isLocked) {
          elementContext.showPropertiesPanel(elementId, data, isLocked, <Locked />, openPanel);
        }
        else {
          elementContext.showPropertiesPanel(elementId, data, isLocked, getPropertiesOnElement(), openPanel);
        }
      }
    },
    elementsClasses: () => {
      let classes = '';

      const isNew = elementContext?.newElementId === elementId;

      if (isContextActive || isNew) {
        const isSelected = elementContext.currentElementId === elementId;

        classes = 'design-element pointer';
        classes += isSelected ? ' active ' : ''
        classes += isNew ? ' new' : ''
        classes += ` open-element-actionbar-${elementId}`
      }

      return classes;
    },
    isSelected: () => {
      return elementContext?.currentElementId === elementId
    },
    isActionBarSelectedElement: () => {
      return elementContext?.actionBarSelectedElementId === elementId
    },
    removeActionBarSelectedElement: () => {
      return elementContext?.removeSelectedActionBarElement(elementId)
    },
    hasContext: () => {
      return isContextActive;
    },
    requiredFieldIndicator: (isRequired) => {
      if (!isRequired) {
        return null
      }

      if (formContext?.processStep && formContext?.processStep !== data.processStep) {
        return null;
      }

      return <span className="text-red-500"> *</span>
    },
    validFieldIndicator: () => {
      if (typeof data?.validateData === "number" && !isValid && isValid !== null) {
        return <span className="text-red-500 pb-2"> (Invalid data)</span>
      }

      if (formContext?.processStep && formContext?.processStep !== data.processStep) {
        return null;
      }

      return null;
    },
    handleBlur: async (value, inputRef, countryCode) => {
      setTouched(true);
      if (!inputRef || !value) {
        return;
      }

      const validator = await isValidInputTextLine(data, value, countryCode);
      if (validator) {
        formContext?.addOrRemoveErrorInput({ id: data.id, field: data.label }, "remove")
        setIsValid(true);
      } else {
        setTimeout(() => inputRef.current.focus(), 0)
        formContext?.addOrRemoveErrorInput({ id: data.id, field: data.label })
        setIsValid(false);
      }
    },
    inputErrorClasses: (isRequired, value) => {
      if (isRequired && touched && !value) {
        return 'border-2 border-red-500'
      }

      if (isRequired && touched && !value && formContext?.processStep && formContext?.processStep !== data.processStep) {
        return 'border-2 border-red-500'
      }

      if (isValid !== null && !isValid) {
        return 'border-2 border-red-500'
      }

      return ''
    },
    readOnlyElementClasses: () => {
      if (!formContext) {
        return ''
      }

      if (!data.readOnly && formContext?.isFormEnabled) {
        return ''
      }

      if (data.type === elementTypes.datePicker
        || data.type === elementTypes.signature
        || data.type === elementTypes.fileUpload
        || data.type === elementTypes.timePicker
        || data.type === elementTypes.button
        || data.type === elementTypes.cancelButton
        || data.type === elementTypes.video
      ) {
        return 'opacity-50 cursor-not-allowed'
      }
      return 'opacity-50'
    },
    isReadOnly: () => {
      return data.readOnly || (formContext && !formContext.isFormEnabled) || formContext?.isAborted
    },
    isReadOnlyDependency: (value, type) => {
      if (type === dependenciesType.none) return false;
      const checkboxDependency = value === 'none' || value === null ? null : document.getElementById(`checkbox-${value}`);
      return checkboxDependency !== null ? !checkboxDependency.checked : false;
    },
    isLocked: () => {
      return isLocked;
    },
    translateTerm: (fallbackText, propertyName = 'term') => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateTerm(data, fallbackText, propertyName)
    },
    translateOption: (optionId, fallbackText, propertyName) => {
      if (!formContext) {
        return fallbackText
      }
      return formContext.translateOption(data, optionId, fallbackText, propertyName)
    },
    translateHelpText: () => {
      if (!formContext) {
        return ''
      }
      const helpText = formContext.translateHelpText(data, data.helpText)
      return helpText
        ? <Tooltip text={helpText} icon={InformationCircleIcon} />
        : null;
    },
  }
}