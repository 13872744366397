import React, { useState, useContext } from 'react'
import TableDropdown from './dropdown'
import NumberInput from './numberinput'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { TrashIcon } from "@heroicons/react/outline"
import './styles.scss'
import { PlusButton } from '../../components/PlusButton';
import { tableInputTypes } from '../../utils/constants';
import TableInput from './input';
import { DialogModes } from '../../contexts';
import { Switch } from '@headlessui/react';

export default function Row({ data, row, newRow, removeRow, valueChanged, onDropDownValueChanged, isAddRow, onInputValueChanged, hiddenTrashIcon, onInputTypeChange, inPercent, currentTable, ...props }) {
  const elementDesign = useElementDesign(data.id, data);
  const { isEditMode, isPreviewMode } = useContext(DialogModes);
  let colorStyle = 'inherit'

  if (data?.text?.color && data?.text?.color.startsWith('#')) {
    colorStyle = data?.text?.color
  }

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit'
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const inputWrapperStyle = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    color: colorStyle
  }

  return (
    (isAddRow && !isPreviewMode) ?
      <div className=' flex col-span-full items-center'>
        <div className='flex-grow bg-blue-500 h-1' />
        <div className='flex-grow-0 text-center'>
          <PlusButton
            onClick={newRow}
          />
        </div>
        <div className='flex-grow bg-blue-500 h-1' />
      </div >
      :
      <>
        <div className={`${!elementDesign.isReadOnly() ? `col-span-${isEditMode ? 7 : 8}` : 'col-span-8'}`}>
          <div className="flex items-start w-full">
            <div className="flex absolute content-center self-center" style={{ left: '-2rem' }}>
              {
                !hiddenTrashIcon &&
                <TrashIcon
                  onClick={removeRow}
                  className={`h-6 w-6 text-black-600  ${roundedCornerClass}`}
                />
              }
            </div>
            <div className={`w-full`}>
              {row.tableInputType === tableInputTypes.inputTextLine ?
                <TableInput data={data} id={row.id} value={row.inputValue} valueChanged={onInputValueChanged} /> :
                <TableDropdown data={data} id={row.id} value={row.selectedValue} valueChanged={onDropDownValueChanged} />}
            </div>
          </div>
        </div>
        <NumberInput row={row} valueChanged={valueChanged} data={data} inPercent={inPercent} currentTable={currentTable} />
          {
            isEditMode && <Switch
            checked={row.tableInputType}
            onChange={onInputTypeChange}
            className={`${row.tableInputType ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`
          }
        >
          <span className="sr-only">change type</span>
          <span
            aria-hidden="true"
            className={`${row.tableInputType ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
        }
      </>
  )
}
