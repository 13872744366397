import * as Property from "../../components/Properties";

export const PlacementGroup = ({
  handleOnChange
}) => {
  return (
    <Property.Group
        title='Placement'>

        <Property.Select
          onChange={handleOnChange}
          label={'Space'}
          name={'space'}
          options={[
            { name: "None", value: 0 },
            { name: "Small", value: 1 },
            { name: "Medium", value: 2 },
            { name: "Large", value: 4 },
          ]}
        />

      </Property.Group>
  );
}