/**
 * @param {*} Text 
 */
export const TextArea = ({ onChange, label, name, prefix, suffix, type, value = "" }) => {

  const handleOnChange = (e, type) => {
    onChange(e, type)
  }

  return (
    <div className="px-4 sm:px-6 pt-3 flex flex-col items-center justify-between">
      <span className="flex-grow flex flex-col place-self-start" id="availability-label">
        <span className="text-sm font-medium text-gray-500 mt-1">{label}</span>
      </span>
      <div className="mt-1 relative rounded-md shadow-sm w-full">

        <textarea
          rows='4'
          type={type || "text"}
          name={name}
          value={value}
          className={`focus:ring-gray-400 focus:border-gray-400 block ${prefix ? 'pl-7 ' : ''} ${suffix ? 'pr-12 ' : ''} sm:text-sm border-gray-300 rounded-md w-full`}
          onChange={(e) => handleOnChange(e, type)}
        />

      </div>
    </div>
  );
}