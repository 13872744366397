import { useState } from 'react';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData))
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value, label } = e.target;

    if (label) {
      actions.handleChangeFile(dialogDefinition, e, data.id, type)
      setProperty(data, "label", label, type);
    } else {
      actions.handleChange(dialogDefinition, e, data.id, type)
    }

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="absolute inset-0">

      <Property.Group
        title='Information'>

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Filename'}
          name={'label'}
          value={data?.label}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={'Description'}
          name={'placeholder'}
          value={data?.placeholder}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Base64Upload
          onChange={handleOnChange}
          label={'Value'}
          name={'value'}
          maxFileSizeInMb={15}
          value={data?.value}
        />

      </Property.Group>
    </div>
  )
}
