import "./styles.scss";
import { useElementDesign } from "../useElementDesign";
import { InlineActionbarElementWrapper } from "../../components/InlineActionbarElementWrapper";

export default function Spacer({ data, ...props }) {
    const widthStyle = `${data?.width ? data.width : 5}px`;
    const heightStyle = `${data?.height ? data.height : 5}px`;

    const style = {
        width: widthStyle,
        height: heightStyle,
    };

    const elementDesign = useElementDesign(data.id, data);

    return (
        <InlineActionbarElementWrapper designElement={elementDesign}>
            <div className="bg-transparent" style={style} />
        </InlineActionbarElementWrapper>
    );
}