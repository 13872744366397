import React, { useContext, useState } from 'react'
import Row from './row'
import { DialogModes, ElementContext, FormContext } from '../../contexts'
import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';

import './styles.scss'
import toast from 'react-hot-toast';
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { tableHasValueSet } from '../../utils/elements';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import { tableValueTypes } from '../../utils/constants';
import { PlusButton } from '../../components/PlusButton';

export default function Table({ data }) {
  const formContext = React.useContext(FormContext);
  const { isPreviewMode, isEditMode } = useContext(DialogModes);
  const elementDesign = useElementDesign(data.id, data);
  const [rows, setRows] = useState(data.tableValues);
  const [clonedData, setSetData] = useState(_.cloneDeep(data));
  const multiStep = useMultiStep(data);
  const elementContext = useContext(ElementContext);
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  let onChange;
  let onNewRow;
  let onDelete;
  let currentTable;

  const handleChanges = async (value) => {
    if(isPreviewMode) return;

    const type = 'text';
    const customEventObject = {
      target: {
        name: "tableValues",
        value
      }
    };

    await elementContext.actions.handleChange(dialogDefinition, customEventObject, clonedData.id, type)

    setProperty(clonedData, customEventObject.target.name, value, type)
    setSetData(clonedData => ({
      ...clonedData
    }));
  };

  if (formContext) {
    // methods when part of actual form
    const currentValues = formContext.inputValues[data.id]?.tableValues || [];
    onChange = (table) => {
      formContext.updateTable(data.id, table);
    }
    onNewRow = () => {
      if (currentTable()) {
        if (currentTable().length < data.maxRows) {
          let newRows = [...currentTable(), { value: null, selectedValue: data.optionWithValues[0].value, selected: false }];

          if(isEmpty())
            newRows = [...newRows, { value: null, selectedValue: data.optionWithValues[0].value, selected: false }];

          onChange(newRows);
        }
        else {
          toast.error('Max rows reached'); // todo make this a warning
        }
      }
      else {
        onChange([{ value: null, selectedValue: data.optionWithValues[0].value, selected: false }]);
      }
    }
    onDelete = (tableValueId) => {
      formContext.deleteTableValue(data.id, tableValueId);
    }
    currentTable = () => {
      return currentValues
    }
  } else {
    // methods when viewing/testing
    onChange = async (table) => {
      handleChanges([...table])
      setRows([...table]);
    }
    onNewRow = () => {
      const initialDisplayValue = "Select from dropdown";
      const selectedValueWithDataOptionsVerify = data.options ? data.options[0] : initialDisplayValue
      if (currentTable()) {
        if (currentTable().length < data.maxRows) {
          onChange([...currentTable(), { id: uuidv4(), value: "0", selectedValue: selectedValueWithDataOptionsVerify }]);
        }
        else {
          toast.warn('Max rows reached');
        }
      }
      else {
        onChange([{ id: uuidv4(), value: "0", selectedValue: selectedValueWithDataOptionsVerify }]);
      }
    }
    onDelete = (tableValueId) => {
      const newRows = rows.filter(r => r.id !== tableValueId)
      setRows([...newRows])
      onChange([...newRows])
    }
    currentTable = () => {
      return rows
    }
  }

  function addRow(evt) {
    evt.preventDefault();
    onNewRow();
  }

  function removeRow(evt, index) {
    evt.preventDefault();
    if (index > -1) {
      onDelete(currentTable()[index].id);
    }
  }

  const changeCurrentTableValue = (index, key, newValue) => {
    const newValues = {...currentTable()[index], [key]: newValue};
      let newArray = Object.assign([], currentTable());
      newArray[index] = newValues;
      return newArray;
  }

  function handleValueChanged(id, newValue) {
    let index = _.findIndex(currentTable(), function (o) { return o.id === id; });
    const newArray = changeCurrentTableValue(index, "value", newValue);
    onChange(newArray);
  }

  const onDropDownValueChanged = (id, newValue) => {
    const index = currentTable().findIndex(r => r.id === id)    
    if (index >= 0) {
      const newArray = changeCurrentTableValue(index, "selectedValue", newValue);
      onChange(newArray);
    }
  }

  const onInputValueChanged = (id, e) => {
    const index = currentTable().findIndex(r => r.id === id)
    if (index >= 0) {
      const newArray = changeCurrentTableValue(index, "inputValue", e.target.value);
      onChange(newArray);
    }
  }

  const getSum = () => {
    return _.sumBy(currentTable(), (r) => {
      return parseFloat(r.value) || 0
    });
  }

  const isEmpty = () => {
    return !currentTable() || currentTable().length === 0;
  }

  function paddingNumber(text) {
    let textLength = text.length

    if (textLength < 3)
      return 8;

    if (textLength < 6)
      return 10;

    if (textLength < 8)
      return 16;

    if (textLength < 13)
      return 24;

    if (textLength < 16)
      return 28;

    if (textLength < 20)
      return 32;

    return 40;
  }

  const classPrefix = "inputTextLine"

  const leadingTextClass = data?.leadingText ? `pl-${paddingNumber(data.leadingText)}` : ''
  const trailingTextClass = data?.trailingText ? `pr-${paddingNumber(data.trailingText)}` : ''
  const labelClass = data?.labelStyle?.bold ? 'font-bold' : ''
  const labelSizeStyle = data?.labelStyle?.size ? data.labelStyle.size : ''
  const labelColorStyle = data?.labelStyle?.color ? data.labelStyle.color : 'inherit'
  const textAlignClass = data?.text?.textAlign ? `${classPrefix}--textalign-${data.text.textAlign}` : ''
  const boldClass = data?.text?.bold ? `${classPrefix}--bold` : ''
  const italicClass = data?.text?.italic ? `${classPrefix}--italic` : ''
  const underlineClass = data?.text?.underline ? `${classPrefix}--underline` : ''
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit'
  const fontSizeStyle = data?.text?.size ? data.text.size : 'inherit'
  const fontFamilyStyle = data?.text?.fontFamily ? `${data.text.fontFamily}, Arial, sans-serif` : `Arial, sans-serif`

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'
  const marginStyle = data?.margin ? data.margin : 'inherit'

  let colorClass = data?.text?.color ? `${classPrefix}--color-${data.text.color}` : ''
  let colorStyle = 'inherit'

  if (data?.text?.color && data?.text?.color.startsWith('#')) {
    colorStyle = data?.text?.color
    colorClass = ''
  }

  // if tableHasValueSet(currentTable()) returns true, pass in anything (ie: 'hasvalue'), so that inputErrorClasses works as expected
  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, tableHasValueSet(currentTable()) ? 'hasvalue' : null)

  const labelStyle = {
    fontSize: labelSizeStyle,
    color: labelColorStyle
  }

  const inputStyle = {
    fontFamily: fontFamilyStyle,
    fontSize: fontSizeStyle,
    backgroundColor: backgroundColorStyle,
    color: colorStyle,
    borderWidth: 0
  }

  const inputWrapperStyle = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    backgroundColor: backgroundColorStyle,
    margin: marginStyle
  }

  const suffixWithTypeValidationAndTranslation = () => {
    return data.tableValueType === tableValueTypes.number ? 
    elementDesign.translateTerm(data.trailingText, 'trailingText') : "%"
  }

  const onInputTypeChange = (e, index) => {
    const newArray = Object.assign([], clonedData.tableValues);
    const newObject = {...newArray[index], tableInputType: e ? 1 : 0};

    newArray[index] = newObject;
    handleChanges(newArray);    
    setSetData()
  }

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div onBlur={elementDesign.handleBlur}>
        {elementDesign.requiredFieldIndicator(data.requiredField)}
        <div className={`grid grid-cols-12 gap-4 ${elementDesign.elementsClasses()} ${inputErrorClasses}`} onClick={(e) => elementDesign.onClick(e, true)}>
          <div style={labelStyle} className={`${labelClass} ${!elementDesign.isReadOnly() || multiStep.isReadOnly ? 'col-span-7' : 'col-span-7'}`}>
            {elementDesign.translateTerm(data.displayLabel, 'displayLabel')}
          </div>
          <div style={labelStyle} className={`${labelClass} col-span-4 flex justify-between`}>
            {elementDesign.translateTerm(data.valueLabel, 'valueLabel')}
            {elementDesign.translateHelpText(data)}
          </div>
          {isEditMode && <div style={labelStyle} className={`${labelClass} col-span-1 flex justify-between`}>
            Type
          </div>
          }
          {
            currentTable()?.map((row, index) =>
              <Row
                key={`tableRow${index}`}
                valueChanged={handleValueChanged}
                onDropDownValueChanged={onDropDownValueChanged}
                onInputValueChanged={onInputValueChanged}
                row={row}
                newRow={addRow}
                removeRow={(evt) => removeRow(evt, index)}
                onInputTypeChange={(e) => onInputTypeChange(e, index)}
                data={data}
                currentTable={currentTable()}
                isAddRow={index === currentTable().length - 1}
                hiddenTrashIcon={currentTable().length <= 2 || !isEditMode}
                inPercent={data.tableValueType === tableValueTypes.percent}
              />
            )
          }

          {
              isEmpty() &&
              <div className=' flex col-span-full items-center'>
              <div className='flex-grow bg-blue-500 h-1' />
              <div className='flex-grow-0 text-center'>
                <PlusButton
                  onClick={(e) => {addRow(e); addRow(e);}}
                />
              </div>
              <div className='flex-grow bg-blue-500 h-1' />
            </div >
          }
          <div className='col-span-4 col-start-9'>
            <div className="max-w-xl w-full col-span-4 justify-self-center">
              {
                !isEmpty() &&
                <div className={`relative ${roundedCornerClass}`}
                  style={inputWrapperStyle}>
                  {
                    data.leadingText &&
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span style={inputStyle} className="text-gray-500 sm:text-sm">
                        {elementDesign.translateTerm(data.leadingText, 'leadingText')}
                      </span>
                    </div>
                  }
                  <input
                    type="text"
                    value={getSum()}
                    style={inputStyle}
                    className={`${leadingTextClass} ${trailingTextClass} element ${roundedCornerClass} block w-full ${classPrefix} ${colorClass} ${textAlignClass} ${boldClass} ${italicClass} ${underlineClass}`}
                    disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
                  />
                  {
                    data.trailingText &&
                    <div className={`absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none`}>
                      <span style={inputStyle} className="text-gray-500 sm:text-sm">
                        {suffixWithTypeValidationAndTranslation()}
                      </span>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </InlineActionbarElementWrapper>
  )
}
