import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';

import './styles.scss'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TableDropdown({ data, id, value, valueChanged, ...props }) {
  const classPrefix = "btn"
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const elementDesign = useElementDesign(data.id, data);
  const multiStep = useMultiStep(data);

  let colorClass = data?.text?.color ? `${classPrefix}--color-${data.text.color}` : ''
  let colorStyle = 'inherit'

  if (data?.text?.color && data?.text?.color.startsWith('#')) {
    colorStyle = data?.text?.color
    colorClass = ''
  }

  const textAlignClass = data?.text?.alignment ? `${classPrefix}--textalign-${data.text.alignment}` : ''
  const boldClass = data?.text?.bold ? `${classPrefix}--bold` : ''
  const italicClass = data?.text?.italic ? `${classPrefix}--italic` : ''
  const underlineClass = data?.text?.underline ? `${classPrefix}--underline` : ''

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit'
  const fontSizeStyle = data?.text?.size ? data.text.size : 'inherit'
  const fontFamilyStyle = data?.text?.fontFamily ? `${data.text.fontFamily}, Arial, sans-serif` : `Arial, sans-serif`
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'transparent'
  const marginStyle = data?.margin ? data.margin : 'inherit'

  const labelStyle = {
    fontSize: fontSizeStyle
  }

  const inputWrapperStyle = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    margin: marginStyle,
    color: colorStyle
  }

  const onChange = (newValue) => {
    valueChanged(id, newValue);
  }

  let currentDisplayValue = value
  const match = data.optionWithValues?.find(d => d.value === value)
  if (match) {
    currentDisplayValue = elementDesign.translateOption(match.id, match.display, "display")
  }
  return (
    <Listbox value={value} onChange={onChange} className='col-span-7' disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}>
      {({ open }) => (
        <>
          <div className="relative col-span-7">
            <Listbox.Button style={inputWrapperStyle} className={`bg-white relative w-full ${roundedCornerClass} pl-3 pr-10 py-2 text-left cursor-default `}>
              <span className="block truncate">
                {currentDisplayValue}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                style={inputWrapperStyle}
                className={`absolute mt-1 w-full bg-white shadow-lg max-h-60 ${roundedCornerClass} py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-40`}
              >
                {data.optionWithValues?.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {elementDesign.translateOption(option.id, option.display, "display")}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
