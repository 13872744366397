import { InformationCircleIcon } from "@heroicons/react/solid";
import { useState, forwardRef, useEffect } from "react"
import { Tooltip } from "../../elements/Tooltip";

/**
 * @param {*} Checkbox 
 */
export const Checkbox = forwardRef((props, ref) => {

  let { onChange, label, name, value, dependency = null, rowReverse } = props
  const initValue = value || value === 'true'

  const [checked, setChecked] = useState(initValue);
  
  useEffect(() => setChecked(initValue), [initValue])

  const dependencyName = dependency && dependency?.current?.id.slice(9);
  const isCheckboxDependencyChecked = dependency?.current?.checked

  const handleOnChange = (e)  => {
    e.target.value = !checked

    onChange(e, 'bool')
    setChecked(!checked)
  }
  
  
  return (
    <div className={`px-4 sm:px-6 pt-3 flex items-center justify-between ${rowReverse ? "flex-row-reverse" : ""}`}>
 
      <span className="flex-grow flex flex-col justify-center" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
     { 
        dependency && !checked && isCheckboxDependencyChecked ?
        <Tooltip text={`To enabled this checkbox you have to unchecked ${dependencyName} property.`} icon={InformationCircleIcon}/>
        :
        <input
          ref={ref}
          id={`checkbox-${label}`}
          name={name}
          checked={checked}
          onChange={handleOnChange}
          type="checkbox"
          className="focus:ring-gray-400 focus:border-gray-400  h-4 w-4 text-indigo-600 border-gray-300 rounded mt-px"
          // {...props}
        />
      }
    </div>
  );
})