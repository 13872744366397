import { useContext } from 'react'
import { ContainerLockedContext, DesignActionbarContext, GlobalDndContext, ShowHideContext } from '../../contexts'
import * as Structures from "../../structures"
import { useContainerDesign } from '../../elements/useContainerDesign'
import './styles.scss'
import { isContainerLocked } from '../../utils/dialogDefinitions'
import { AddActions } from './addActions'
import { Actions } from './actions'
import { backgroundSizeOptions } from '../../utils/constants';
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { DialogModes } from '../../contexts'
import { DragHandle } from "../../components/DragAndDrop/dragHandle"

export default function Container({
  data,
  showAllContainers,
  activeContainer,
  totalContainers,
  prevContainer,
  nextContainer,
  finishedContainer,
  marginOverride,
  paddingOverride,
  dialogData,
  draggableProvided = null,
  onDragHandleClick,
  ...props
}) {
  const showHideContext = useContext(ShowHideContext);
  const designActionbarContext = useContext(DesignActionbarContext);
  const { isEditMode } = useContext(DialogModes);
  const globalDndContext = useContext(GlobalDndContext)
  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderColor ? `${data.borderColor}` : 'inherit'
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'transparent'
  const backgroundImage = data?.backgroundImage ? `url(${data.backgroundImage})` : 'none'
  const backgroundSize = backgroundSizeOptions.find(option => option.value === data?.backgroundSize)
  const backgroundSizeStyle = backgroundSize?.name ? backgroundSize.name.toLowerCase() : 'auto'
  const backgroundRepeatClass = data?.backgroundRepeat ? "bg-repeat" : 'bg-no-repeat'

  const marginStyle = data?.margin ? data.margin : 0
  const paddingStyle = data?.padding ? data.padding : 0
  let dialogTypeClass = ''

  const style = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    backgroundColor: backgroundColorStyle,
    margin: marginOverride ? marginOverride : marginStyle,
    padding: paddingOverride ? paddingOverride : paddingStyle,
    backgroundImage: backgroundImage,
    backgroundSize: backgroundSizeStyle
  }

  const gapClass = data?.space ? `gap-${data.space}` : ''
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const isNewPageClass = data?.newPage ? 'page-break' : 'no-break'
  const avoidBreakInsideClass = data?.avoidBreakInside ? 'print-break-inside-avoid' : '';

  const isLocked = isContainerLocked(data)
  const containerDesign = useContainerDesign(data.id, data, isLocked)

  const {
    disableAddContainer
  } = designActionbarContext || {}

  onDragHandleClick = containerDesign?.onClick;

  const dragAndDropEnabled = isEditMode && !isLocked
  return (
    <ContainerLockedContext.Provider
      value={{
        isLocked,
        id: data?.id
      }}
    >
      <div className='relative'
        {...draggableProvided?.draggableProps}
        ref={draggableProvided?.innerRef}
      >

        <div
          className={`flex flex-col print:block ${avoidBreakInsideClass} ${gapClass} ${roundedCornerClass} ${dialogTypeClass} ${containerDesign.containersClasses(data?.backgroundColor)} ${backgroundColorStyle === 'transparent' ? "print-no-background " : "print:px-14 "}  ${isNewPageClass} ${backgroundRepeatClass}`}
          style={style}
          onMouseOver={containerDesign.onHover}
        >
          {
            !dragAndDropEnabled &&
            data?.rows?.map((row, i) =>
              <Structures.Row
                key={`row-${row.id}`}
                data={{
                  ...row,
                }}
                dialogData={dialogData}
              />
            )
          }
          {
            dragAndDropEnabled &&
            <Droppable droppableId={data?.id} type={"row"}>
              {({ droppableProps, innerRef, placeholder }) => (
                <div {...droppableProps} ref={innerRef}>
                  {data?.rows?.map((row, i) => {
                    return (
                      <Draggable
                        key={row.id}
                        draggableId={`row-${row.id}`}
                        index={i}
                        isDragDisabled={!globalDndContext.isTypeEnabled("row")}
                      >
                        {(draggableProvidedRow, dndProps) => (
                          <DragHandle
                            dragHandleProps={draggableProvidedRow.dragHandleProps}
                            isHandleVisible={!globalDndContext.hasActiveDrag()}
                            tooltip="Drag row"
                            forSortableType="row"
                          >
                            <Structures.Row
                              key={`row-${row.id}`}
                              data={{ ...row }}
                              dialogData={dialogData}
                              draggableProvided={draggableProvidedRow}
                              dndProps={dndProps}
                            />
                          </DragHandle>
                        )}
                      </Draggable>
                    )
                  })
                  }
                  {placeholder}
                </div>
              )}
            </Droppable>
          }
          {
            !showAllContainers && !data.static &&
            <div className='sm:flex-row mt-10 print:hidden'>

              {
                totalContainers > activeContainer &&
                <button
                  type="button"
                  onClick={(e) => showHideContext?.goToNextContainer(e)}
                  className="float-right items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                >
                  Next
                </button>
              }

              {
                (finishedContainer || activeContainer > 0) &&
                <button
                  type="button"
                  onClick={(e) => showHideContext?.goToPrevContainer(e)}
                  className="mr-2 float-right items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                >
                  Back
                </button>
              }

            </div>
          }
        </div>
        {
          containerDesign.isSelected() &&
          <div
            className='absolute h-16 cursor-pointer'
            style={{
              top: "-5px",
              right: "-50px",
              width: "45px"
            }}
            {...draggableProvided?.dragHandleProps}
          >

            <Actions onShowPropertiesClick={containerDesign.onClick} container={data} />
          </div>
        }
      </div>
      {
        !disableAddContainer && containerDesign.isSelected() &&
        <div className={`flex -ml-10 -mr-24`}>
          <div className='flex-grow'>
            <AddActions containerId={data.id} />
          </div>
        </div>
      }

      {
        !containerDesign.isSelected() &&
        <div className={`flex -ml-10 -mr-24`} style={{ height: '27px' }}>
        </div>
      }

    </ContainerLockedContext.Provider>
  )
}
