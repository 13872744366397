
import { useEffect } from "react";
import ColorPicker from "../ColorPicker";

/**
 * @param {*} Color 
 */
export const Color = ({
  onChange,
  label,
  name,
  initColor,
  defaultColor,
}) => {

  useEffect(() => {
    // if there is no initColor, use the default color and trigger the change event
    if (!initColor) {
      handleOnChange({
        target: {
          value: defaultColor,
          name,
        }
      })
    }
  }, [])

  const handleOnChange = (e, type) => {
    onChange(e, type)
  }

  return (
    <div className="px-4 sm:px-6 pt-3 flex items-center justify-between">
      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      <ColorPicker name={name} onChange={handleOnChange} alignRight={true} initColor={initColor} />
    </div>
  );
}